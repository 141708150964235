import axios from 'axios';
import React, { useState } from 'react'
import CONFIG from '../../utils/Config';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { Button, Checkbox, FormControl, TextField } from '@mui/material';
import { useClient } from '../../utils/context/ClientContext';
import { useAuth } from '../../utils/authentication/auth';
import toast, { Toaster } from 'react-hot-toast';
import { Loader } from '../../utils/icons/Loader';

export default function AddUserForm() {
  const clientContext = useClient();
  const [fullName, setFullName] = useState();
  const [mailId, setMailId] = useState();
  const [confirmMailId, setConfirmMailId] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [errors, setErrors] = useState(() => {
    return {
      fullName: false,
      mailId: false,
      confirmMailId: false,
      mobileNumber: false
    }
  })

  const handleCreateNewUser = () => {   
    if(!fullName ||!mailId ||!confirmMailId ||!mobileNumber){
      toast.error("All fields are required!");
      setErrors({
        fullName: fullName? false : true,
        mailId: mailId? false : true,
        confirmMailId: confirmMailId? false : true
      })
      return;
    }
    if(mailId!== confirmMailId){
      toast.error("Email and Confirm Email should match!");
      return;
    }
    let data = {
        fullName:fullName,
        mailId:mailId,
        mobileNumber:mobileNumber
    }

    axios({
      url: CONFIG.URL + "user/register",      
      method:"POST",
      data:data,     
      withCredentials: true, 
      headers: {
        'X-TenantID': clientContext.tenantID
      }
    }).then((res) => {
       if(res.data.status === "Success"){
        toast.success("User added successfully!");
       }else{
        toast.error("Something went wrong try again after sometime!");
       }
    }).catch((err) => {
      if(err.response.status === 403){
        toast.error('Token Expired please logout and login!');
      }
      toast.error("Something went wrong try again after sometime!");
      console.error(err.message);
    })
  }
  return (
    <div>   
        <Toaster/>  
        {/* <Loader/>                */}
      <div className="mt-5 md:mt-10 p-5 md:p-10 w-11/12 md:w-8/12 lg:w-4/12 max-w-7xl mx-auto shadow-md rounded-lg ">
        <div>
          <h3 className="text-xl font-primary-bold text-gray-800">
            Add New User
          </h3>
          <p className="text-gray-500 font-primary-regular text-sm">Here you can create a new user for your organization to manage your administration</p>
        </div>
        <hr className="my-5 border-gray-400 " />
        <FormControl>
          <div className="mb-4">           
            <TextField                            
              value={fullName}                                                 
              onChange={(e) => setFullName(e.target.value)}             
              label="Full Name"
              variant="outlined"               
              fullWidth={true}
              required={true}    
              error={errors.fullName}                             
            />
          </div>
          <div className="mb-4">
            <TextField
              value={mailId}                                                 
              onChange={(e) => setMailId(e.target.value)}             
              label="Email Id"
              variant="outlined"
              fullWidth={true}
              required={true}
              error={errors.mailId}  
            />
          </div>
          <div className="mb-4">            
            <TextField                            
              value={confirmMailId}   
              type='password'                         
              onChange={(e) => setConfirmMailId(e.target.value)}             
              label="Re-Enter Email Id"
              variant="outlined"               
              fullWidth={true}
              required={true}    
              error={errors.confirmMailId}                             
            />
          </div>
          <div className="mb-4 flex flex-col gap-3">            
            <PhoneInput
                enableSearch="true"                                   
                country="in"
                onChange={setMobileNumber}  
                autoFormat={true}                                      
                inputStyle={{ width: "100%", border: '1px solid #ccc', borderRadius: '10px' }}
                />
          </div>                
          <div className='flex gap-2 items-start text-gray-600 mt-10'>
          <Checkbox />
            <p className='text-xs'>
                By clicking accept you are aware of all the consequences of providing provisions to The newly created user and he/she will have all the administrator previleges to manage all the artifacts and monitoring.
            </p>
          </div>          
          <div className="text-center mt-10">
            <Button variant='contained' onClick={handleCreateNewUser}>Create New User</Button>
          </div>
        </FormControl>                
      </div>
    </div>
  )
}
