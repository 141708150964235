import React, { Fragment, useState } from "react";
import icons from "../../utils/Icons";
import { useEffect } from "react";
import axios from "axios";
import CONFIG from "../../utils/Config";
import { useClient } from "../../utils/context/ClientContext";
import { useWidgets } from "../../utils/context/WidgetContext";
import { ClockIcon } from "@heroicons/react/24/solid";
import ReloadIcon from "../../utils/icons/ReloadIcon";
import { Tooltip } from "@mui/material";
import { useAuth } from "../../utils/authentication/auth";

function Widget({ id }) {
  const clientContext = useClient();
  const [widgetData, setWidgetData] = useState();
  const [widgetLoaded, setWidgetLoaded] = useState(false);
  const widgetContext = useWidgets();
  const [lastLoadTime, setLastLoadTime] = useState();  
  const auth = useAuth();
  useEffect(() => {
    if(widgetContext === undefined || widgetContext === null || widgetContext.getWidget(id) === undefined || widgetContext.getWidget(id) === null){
      axios({
        url: CONFIG.URL + "widgets/getWidgetData?widgetId=" + id,
        method: "GET",
        withCredentials: true,
        headers: {
          'X-TenantID': clientContext.tenantID
        }
      }).then((res) => {        
        setWidgetData(res.data);
        let currentTime = new Date();
        let tempWidgetData = {
          widget : res.data,
          updateTime : currentTime
        }
        widgetContext.addWidget(tempWidgetData);
        setWidgetLoaded(true);
      }).catch((err) => {
        console.error(err);
      })
    }else{    
      let widgetData = widgetContext.getWidget(id);      
      setWidgetData(widgetData.widget);
      let time = new Date(widgetData.updateTime);
      const date = new Date();
      var diff = date.getTime() - time.getTime();
      var msec = diff;
      var hh = Math.floor(msec / 1000 / 60 / 60);
      msec -= hh * 1000 * 60 * 60;
      var mm = Math.floor(msec / 1000 / 60);
      msec -= mm * 1000 * 60;
      var ss = Math.floor(msec / 1000);
      msec -= ss * 1000;
      setLastLoadTime(hh + ":" + mm + ":" + ss);      
      setWidgetLoaded(true);
    }
    
  }, [widgetData]);
  
  const handleRefresh = () => {
    axios({
      url: CONFIG.URL + "widgets/getWidgetData?widgetId=" + id,
      method: "GET",
      headers: {
        'X-TenantID': clientContext.tenantID,
      }
    }).then((res) => {        
      setWidgetData(res.data);
      let currentTime = new Date();
      let tempWidgetData = {
        widget : res.data,
        updateTime : currentTime
      }
      widgetContext.reloadWidget(tempWidgetData);
      setLastLoadTime(0);
      setWidgetLoaded(true);
    }).catch((err) => {
      console.error(err);
    })
  }

  return (
  <>
    {widgetLoaded === true ? <div
      className="widget"
      style={{ backgroundRepeat: "no-repeat", backgroundPosition: "right" }}
    >
      <div className="p-5 flex justify-between items-start gap-3">
        <div>
          <h3 className="font-primary-medium">{widgetData.name}</h3>            
          {widgetData.amount > 0 ? (
            <span className="flex my-2 w-14 bg-green-100 p-1 text-xs text-green-800 font-primary-bold rounded-full">
              {icons.arrowUp} {widgetData.message}
            </span>
          ) : (
            <span className="flex my-2 w-20 bg-red-100 p-1 text-xs text-red-800 font-primary-bold rounded-full">
              {icons.arrowUp} {widgetData.message}
            </span>
          )}

          <h2 className="font-primary-bold text-3xl  ">
            {widgetData.amount || widgetData.amount == 0
              ? "₹ " + widgetData.amount
              : widgetData.data}
          </h2>
        </div>
      </div>
      <div className="flex items-center justify-between gap-3 absolute bottom-0 w-full  py-3 px-5 bg-white text-gray-700 rounded-b-xl h-12  border-t-2">              
        <div className="font-primary-regular text-sm text-blue-gray-900 flex items-center gap-1">        
          <span><ClockIcon strokeWidth={2} className="h-4 w-4 text-blue-gray-400" /></span> Last updated {lastLoadTime} mins ago
        </div>
        <Tooltip title="Refresh" arrow placement="bottom">
          <div className="cursor-pointer" onClick={handleRefresh}>
            <ReloadIcon className="w-6 h-6"/>
        </div>
        </Tooltip>
        
      </div>
    </div> : 
      <div className="w-20">        
      </div>      
    }
  </>);
};

export default Widget;

