const config = {
  chart: {
    toolbar: {
      show: true,
    },
  },
  title: {
    show: "",
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    axisTicks: {
      show: true,
    },
    axisBorder: {
      show: true,
    },
    labels: {
      style: {
        colors: "blue-gray",
        fontSize: "13px",
        fontFamily: "fontFamily",
        fontWeight: 300,
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: "blue-gray",
        fontSize: "13px",
        fontFamily: "inherit",
        fontWeight: 300,
      },
    },
  },
  grid: {
    show: true,
    borderColor: "#dddddd",
    strokeDashArray: 2,
    xaxis: {
      lines: {
        show: true,
      },
    },
    padding: {
      top: 5,
      right: 20,
    },
  },
  fill: {
    opacity: 0.8,
  },
  tooltip: {
    theme: "dark",
  },
  stroke: {
    lineCap: "round",
    curve: 'smooth'
  },  
  plotOptions: {
    bar: {
      columnWidth: "16%",
      borderRadius: 5,
    }
  },
  markers: {
    size: 0,
  },
};
const CHART_TYPE = {
  BAR:{
    id:2,
    name: "bar",    
  },
  LINE:{
    id:3,
    name:"line"
  },
  AREA:{
    id:4,
    name:"area"
  }
}
let colors = ["#0288d1","#388e3c","#4f46e5","#be185d"];
const chartConfig = {
  CONFIG:config,
  TYPES:CHART_TYPE,
  COLORS:colors
}

export default chartConfig;