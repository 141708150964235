import DashboardIcon from "../../utils/icons/DashboardIcon";
import React from "react";
import {RecentOrderItem} from "./RecentOrderItem";
import OrderCard from "./OrderCard";


export const RecentOrderList = ({ recentOrders }) => {
    return (
        <>
            <div>
                <div >
                    <h4 className={"font-medium py-2 border-b border-gray-100 "}>Recent Deliveries</h4>
                    <div className={"overflow-y-auto h-[500px] scrollbar-hide"}>
                        {recentOrders ? recentOrders.map((data) => {
                            return (<RecentOrderItem key={data.orderId} data={data}/>);
                        }) : <p className={'text-gray-500 text-sm text-center font-normal py-5'}>No Deliveries Found.</p>}
                    </div>
                </div>
            </div>


        </>
    )
}
