import React, { useEffect, useRef, useState } from 'react'
import HambergerIcon from '../../utils/icons/HambergerIcon'
import CloseIcon from '../../utils/icons/CloseIcon'
import LOGO from '../../assets/images/CleFactura.png'
import { NavLink } from 'react-router-dom';
import HomeIcon from '../../utils/icons/HomeIcon';
import ContactIcon from '../../utils/icons/ContactIcon';
import OfferIcon from '../../utils/icons/OfferIcon';
import TableIcon from '../../utils/icons/TableIcon';
import ProductIcon from '../../utils/icons/ProductIcon';
import DownArrowIcon from '../../utils/icons/DownArrowIcon';
import { motion } from "framer-motion";
import UserIcon from '../../utils/icons/UserIcon';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import DashboardIcon from "../../utils/icons/DashboardIcon";
import SupportIcon from "../../utils/icons/SupportIcon";

export const Menubar = () => {    
    const [menuOpen, setOpenMenu] = useState(false);
    const [activeLink, setActiveLink] = useState(); 
    const slideNavbar = useRef();


    const menuList = [
        { label: "Home", icon: <HomeIcon className="w-5 h-5" />, link: "/home" },
        { label: "Dashboard", icon: <DashboardIcon className="w-5 h-5" />, link: "/dashboard" },
        {
            label: "Products",
            icon: <ProductIcon className="w-5 h-5" />,
            link: "/products",
            submenu: [
                { label: "Add Products", link: "/register" },
                { label: "View Products", link: "/viewProducts" }
            ]
        },
        {
            label: "Offers",
            icon: <OfferIcon className="w-5 h-5" />,
            link: "/offers",
            submenu: [ // Added submenu items
                { label: "Create Offers", link: "/createOffer" },
                { label: "View Offers", link: "/viewOffers" }
            ]
        },
        { label: "Table", icon: <TableIcon className="w-5 h-5" />, link: "/registerTable" },            
        {
            label: "IAM",
            icon: <UserIcon className="w-5 h-5" />,            
            submenu: [
                { label: "Add New User", link: "/iam/addUser" },
                { label: "Manage Users", link: "/iam/manageUser" },
                { label: "Roles & Permissions", link: "/iam/roles" }
            ]
        },
        { label: "Support", icon: <SupportIcon className="w-5 h-5" />, link: "/contactUs" }
    ]
    const menuContent = [
        { label: "Terms & Service", link: "https://tforte.in/terms" },
        { label: "Cancellation Policy", link: "https://tforte.in/privacy" },
        { label: "Privacy & Policy", link: "https://tforte.in/privacy" },
        { label: "Contact Us", link: "mailto: hello@clefactura.com" }
    ]
    const handleMenuOpen = () => {       
        setOpenMenu(!menuOpen)
    }
    const handleSubMenu = (e) => {
        let classlist = e.currentTarget.lastChild.classList.toString();
        if (classlist.indexOf("hidden") > -1) {
            e.currentTarget.lastChild.classList = classlist.replaceAll("hidden", "");
        } else {
            e.currentTarget.lastChild.classList = classlist + " hidden ";
        }

    }
    const handleActive = (link) => {
        setActiveLink(link)
        handleMenuOpen();
    }

    return (
        <div className='flex items-center gap-7'>
            <button onClick={handleMenuOpen}>
                {!menuOpen ? (<HambergerIcon className="w-7 h-7 text-gray-800 fill-current" />) : (<ArrowLeftIcon className="absolute left-5 md:left-14 top-3 md:top-8 w-5 md:w-6 h-5 md:h-6 text-gray-500 fill-current z-[1000]" />)}
            </button>
            {menuOpen && (<div className='z-50'>
                <div className='absolute left-0 top-0 w-full h-svh backdrop-blur-0 bg-black/30' onClick={handleMenuOpen}></div>
                <motion.div
                    ref={slideNavbar}
                    className={`absolute left-0 top-0 w-full md:w-4/12 xl:w-3/12 py-16 md:pt-20 px-5 md:px-10 bg-sky-50 md:bg-white h-lvh overflow-y-auto `}>
                    <div className='w-full mx-auto px-5 md:min-h-full flex flex-col justify-between bg-white  md:bg-none rounded-2xl  md:rounded-none shadow md:shadow-none'>
                        <div>
                            {menuList.map((menu, index) => (<div key={index} className='*:font-primary-bold'>
                                {menu.submenu ? (
                                    <div className="border-b border-gray-100" onClick={handleSubMenu}>
                                        <div className="flex items-center gap-3 py-3 border-b border-gray-100 cursor-pointer" >
                                            <div>{menu.icon}</div>
                                            <div>{menu.label}</div>
                                            <div className='ml-auto mr-5'><DownArrowIcon className="w-5 h-5"/></div>
                                        </div>
                                        <div className="pl-8 hidden" id={index}>
                                            {menu.submenu.map((subitem, subIndex) => (
                                                <NavLink key={subIndex} to={subitem.link} className={`${activeLink === subitem.link ? 'text-primary-blue' : 'text-gray-600'}`}
                                                    onClick={() => handleActive(subitem.link)}>
                                                    <div className='flex gap-3 items-center py-3 border-b border-gray-100 text-sm'>
                                                        <span>{subitem.label}</span>
                                                    </div>
                                                </NavLink>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <NavLink to={menu.link} className={`${activeLink === menu.link ? 'text-primary-blue' : 'text-gray-700'}`}
                                        onClick={() => handleActive(menu.link)}>
                                        <div className='flex gap-3 items-center py-3 border-b border-gray-100'>
                                            {menu.icon}
                                            <span>{menu.label}</span>
                                        </div>
                                    </NavLink>
                                )}
                            </div>))}


                        </div>
                        <div className='border-t  hidden md:block border-gray-200 py-2.5'>
                            <p className="text-gray-800 font-primary-bold">
                                For Enquiry
                            </p>
                            <p className="text-gray-700 font-primary-regular cursor-pointer" onClick={() => window.location = "mailto:hello@clefactura.com"}>
                                hello@clefactura.com
                            </p>
                        </div>
                    </div>
                    <div className='w-full md:hidden mx-auto py-2 px-3 bg-white rounded-2xl shadow my-5'>
                        {menuContent.map((menu, index) => (<NavLink key={index} onClick={() => window.location = menu.link}  >
                            <div className=' py-2 px-3'>
                                <span className='text-gray-800'>{menu.label}</span>
                            </div>
                        </NavLink>))}
                    </div>
                    <div class="flex place-self-end gap-2.5 mt-20 items-center border-t border-gray-200 py-5 md:hidden">
                        <img src={LOGO} alt="Clè-Factura Logo" className="footer-logo  cursor-pointer" onClick={() => window.location = "https://clefactura.tforte.in"} />
                        <div className='flex flex-col justify-between items-start'>
                            <div class="flex">
                                <h2 class="text-lg font-primary-semibold">Clè-Factura</h2>
                                <sup class="uppercase text-xs font-primary-semibold"
                                >TM</sup
                                >
                            </div>
                            <p class="text-gray-600 font-primary-light text-xs">Your companion in scaling your Business</p>
                        </div>
                    </div>
                </motion.div></div>
            )}
        </div>
    )
}