import React, { useState, useEffect } from 'react';

const Timer = (props) => {  

  useEffect(() => {
    const timer = setInterval(() => {
      if (props.time > 0) {
        props.setTime(prevSeconds => prevSeconds - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [props.time]);

  return (
    <div>
      <h1>{props.time} seconds</h1>
    </div>
  );
};

export default Timer;