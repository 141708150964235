import React, { useEffect, useState } from "react";
import Footer from "../elements/Footer";
import { motion } from "framer-motion";
import CHART_CONFIG from "../../utils/ChartConfig";
import { StatisticsChart } from "../elements/widgets/charts/Chart";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ToolTIP,
  Filler,
  Legend
} from "chart.js";
import Widget from "../elements/Widget";
import { useAuth } from "../../utils/authentication/auth";
import { useClient } from "../../utils/context/ClientContext";
import { APPLICATION_ENDPOINTS, AUTH_TYPE, fetchData, METHOD_TYPE } from "../../utils/RequestUtils";
import { Loader } from "../../utils/icons/Loader";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

ChartJS.register(
    ArcElement,
    ToolTIP,
    Legend,
    CategoryScale,
    PointElement,
    LineElement,
    Title,
    ToolTIP,
    Filler
);

export default function Dashboard() {
  const clientContext = useClient();
  const auth = useAuth();
  const [widgets, setWidgets] = useState([]);
  const [widgetsLoaded, setWidgetsLoaded] = useState(false);

  useEffect(() => {
    fetchData(APPLICATION_ENDPOINTS.GET_WIDGETS, undefined, METHOD_TYPE.GET, AUTH_TYPE.AUTHENTICATED, clientContext.tenantID)
        .then((res) => {
          setWidgets(res.data);
          setWidgetsLoaded(true);
        }).catch((err) => {
      alert(err.message);
      console.error(err.message);
    })
  }, [clientContext.tenantID]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    // Check if dragging within the same droppable area
    if (result.source.droppableId === result.destination.droppableId) {
      const reorderedWidgets = Array.from(widgets);
      const [removed] = reorderedWidgets.splice(result.source.index, 1);
      reorderedWidgets.splice(result.destination.index, 0, removed);
      setWidgets(reorderedWidgets);
    }
  };

  return (
      <div>
        {widgetsLoaded === false ? <Loader /> : <></>}
        {widgetsLoaded === true ?
            <motion.div
                animate={{ y: -10 }}
                transition={{ duration: 0.5 }}
                className=" grid content-between mb-10"
            >
              <div className="px-1 md:px-10 mt-10 md:mt-10 w-11/12 md:w-10/12 mx-auto">

                {/* First Draggable Section: Widgets */}
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="widgets" direction={window.innerWidth > 768 ? "horizontal" : "vertical"}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            className={"w-full flex-grow grid grid-cols-1 md:grid-cols-3 gap-5"}
                            {...provided.droppableProps}
                        >
                          {widgets.map((widget, index) => (
                              widget.type === 1 && (
                                  <Draggable key={widget.id} draggableId={widget.id.toString()} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                          <Widget key={widget.id} id={widget.id} />
                                        </div>
                                    )}
                                  </Draggable>
                              )
                          ))}
                          {provided.placeholder}
                        </div>
                    )}
                  </Droppable>

                  {/* Second Draggable Section: Charts */}
                  <Droppable droppableId="charts" direction={window.innerWidth > 768 ? "horizontal" : "vertical"}>
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            className={"w-full flex-grow grid grid-cols-1 md:grid-cols-2 gap-5 mt-5"}
                            {...provided.droppableProps}
                        >
                          {widgets.map((widget, index) => (
                              (widget.type === CHART_CONFIG.TYPES.BAR.id ||
                                  widget.type === CHART_CONFIG.TYPES.LINE.id ||
                                  widget.type === CHART_CONFIG.TYPES.AREA.id) && (
                                  <Draggable key={widget.id} draggableId={widget.id.toString()} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={'md:col-span-1'}
                                        >
                                          <StatisticsChart
                                              key={widget.id}
                                              id={widget.id}
                                              type={widget.type}
                                          />
                                        </div>
                                    )}
                                  </Draggable>
                              )
                          ))}
                          {provided.placeholder}
                        </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </motion.div>
            : <></>
        }
      </div>
  );
}