import React from 'react';

const Table = ({ columns, rows }) => {
  return (
    <table className="divide-y divide-blue-200 border border-gray-300 overflow-y-auto">
      <thead className="bg-primary">
        <tr>
          {columns.map((column, columnIndex) => (
            <th
              key={columnIndex}
              scope="col"
              className="px-6 py-3 text-left text-lg text-gray-50 tracking-wider border-r border-gray-300"
            >
              {column.content}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-blue-100 font-primary-regular ">
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-gray-50' : ''}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex} className="px-6 py-4 whitespace-nowrap border-r border-gray-300">
                <div className="text-gray-900">{cell.content}</div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
