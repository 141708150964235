import React, { useEffect, useState } from "react";
import axios from "axios";
import CONFIG from "../../utils/Config";
import ImagePlaceholder from '../../assets/images/image_placeholder.svg'
import EditIcon from "../../utils/icons/Edit";
import Select from "react-select";
import { useAuth } from "../../utils/authentication/auth";
import { useClient } from "../../utils/context/ClientContext";
import { Sheet } from "react-modal-sheet";
import {Dropdown} from "./inputs/Dropdown";

const ProductEditForm = ({ visibility, productData, handleVisibility }) => {
  const auth = useAuth();
  const clientContext = useClient();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [productName, setProductName] = useState(productData.name);
  const [productDescription, setProductDescription] = useState(productData.description);
  const [productPrice, setProductPrice] = useState(productData.rate);
  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(productData.category.id);
  const [selectedSubCategory, setSelectedSubCategory] = useState(productData.subCategory.id);
  const [selectedPreparationTime, setSelectedPreparationTime] = useState(productData.preparationTime.id);
  const [subCategories, setSubCategories] = useState(null);
  const [preparationTime, setPreparationTime] = useState(null);
  const [errors, setErrors] = useState({
    productName: "",
    productDescription: "",
    productPrice: "",
    productCategory: "",
  });

  useEffect(() => {
    axios({
      url: CONFIG.URL + "public/product/getCategories",
      method: "GET",
      headers: {
        'X-TenantID': clientContext.tenantID,
        Authorization: auth.authToken
      }
    }).then((res) => {
      if (res.data.length > 0) {
        let options = [];
        res.data.map((e) => {
          let option = {
            value: e.id,
            label: e.name
          }
          options.push(option);
        });
        if (options.length > 0) {
          setCategories(options)
        }
      }
    }).catch((err) => {
      console.error(err);
    });


    axios({
      url: CONFIG.URL + "public/product/getSubCategories",
      method: "GET",
      headers: {
        'X-TenantID': clientContext.tenantID,
        Authorization: auth.authToken 
      }
    }).then((res) => {
      if (res.data.length > 0) {
        let options = [];
        res.data.map((e) => {
          let option = {
            value: e.id,
            label: e.label
          }
          options.push(option);
        });
        if (options.length > 0) {
          setSubCategories(options)
        }
      }
    }).catch((err) => {
      console.error(err);
    });
    axios({
      url: CONFIG.URL + "public/product/getSubCategories",
      method: "GET",
      headers: {
        'X-TenantID': clientContext.tenantID,
        Authorization: auth.authToken 
      }
    }).then((res) => {
      if (res.data.length > 0) {
        let options = [];
        res.data.map((e) => {
          let option = {
            value: e.id,
            label: e.label
          }
          options.push(option);
        });
        if (options.length > 0) {
          setSubCategories(options)
        }
      }
    }).catch((err) => {
      console.error(err);
    });
    axios({
      url: CONFIG.URL + "public/product/getPreparationTime",
      method: "GET",
      headers: {
        'X-TenantID': clientContext.tenantID,
        Authorization: auth.authToken 
      },
    }).then((res) => {
      if (res.data.length > 0) {
        let options = [];
        res.data.map((e) => {
          let option = {
            id: e.id,
            label: e.label
          }
          options.push(option);
        })
        if (options.length > 0)
          setPreparationTime(options);
      }
    }).catch((err) => {
      console.error(err);
    });

    setIsModalOpen(visibility);
  }, [visibility]);

  const closeModal = () => {
    setIsModalOpen(false);
    handleVisibility();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
  };

  const handleImage = () =>{
    console.log("work");
  }

  return (
    <>
      {isModalOpen && (
        <Sheet
          isOpen={isModalOpen}
          onClose={() => closeModal()}
          
          className="md:w-6/12 mx-auto"
        >
          <Sheet.Container>
            <Sheet.Header />
              <Sheet.Content >
                <Sheet.Scroller>              
                <div className="flex justify-between items-center w-10/12 mx-auto">
                  <h1 className="text-xl md:text-3xl font-primary-semibold text-gray-900 my-1 md:my-3   text-center">
                    {productData.name}
                  </h1>
                <div className='relative ' onClick={handleImage}>
                  <img
                    src={ImagePlaceholder}
                    alt=""
                    className="w-16 md:w-24 h-16 md:h-24 rounded-full  object-bottom "
                    
                  />
                  <div className="absolute bottom-0  flex justify-center items-center h-8 md:h-12 w-8 md:w-24 bg-gray-800/20 rounded-bl-full rounded-br-full ">
                    <EditIcon className="w-6 h-6 text-gray-800 fill-current opacity-100" />
                  </div>
                </div>
                </div>          
                <div>                 
                  <form
                    onSubmit={handleSubmit}
                    className="mt-2 md:my-5 mb-5  px-8 md:px-20 *:mb-1 *:md:mb-3 text-left"
                  >
                    <div>
                      <label className="font-primary-medium text-gray-900">Name</label>
                      <input
                        type="text"
                        id="productName"
                        name="productName"
                        value={productName}
                        onChange={(e) => setProductName(e.target.value)}
                        placeholder="Product Name"
                        className={`form-input-text ${errors.productName ? "border-red-500" : ""
                          }`}
                      />
                      {errors.productName && (
                        <p className="form-error-msg">{errors.productName}</p>
                      )}
                    </div>
                    <div>
                      <label className="font-primary-medium text-gray-900">Description</label>
                      <textarea
                        id="productDescription"
                        name="productDescription"
                        value={productDescription}
                        onChange={(e) => setProductDescription(e.target.value)}
                        placeholder="Short Description"
                        className={`form-input-text resize-none h-20  ${errors.productDescription ? "border-red-500" : ""
                          }`}
                      />
                      {errors.productDescription && (
                        <p className="form-error-msg">
                          {errors.productDescription}
                        </p>
                      )}
                    </div>
                    <Dropdown options={categories} defaultValue={selectedCategory} setValue={setSelectedCategory} label={'Category'} className={'w-full'}/>
                    <Dropdown options={subCategories} defaultValue={selectedSubCategory} setValue={setSelectedSubCategory} label={'Sub Category'} className={'w-full'}/>
                    <Dropdown options={preparationTime} defaultValue={selectedPreparationTime} setValue={setSelectedPreparationTime} label={'Preparation Time'} className={'w-full'}/>
                    <div className="flex flex-col md:flex-row gap-4 rounded-lg *:w-full">
                      <div className="w-full md:w-6/12">
                        <label className="font-primary-medium text-gray-900">Rate</label>
                        <input
                          type="text"
                          id="productPrice"
                          name="productPrice"
                          value={productPrice}
                          onChange={(e) => setProductPrice(e.target.value)}
                          placeholder="&#8377; Unit Price"
                          className={`my-1 py-1.5 px-3 outline-none w-full text-base font-primary-regular border border-primary-gray focus:outline-none focus:border-sky-500 focus:shadow-none  rounded-lg${errors.productPrice ? "border-red-500" : ""
                            }`}
                        />
                        {errors.productPrice && (
                          <p className="form-error-msg">{errors.productPrice}</p>
                        )}
                      </div>
                      <div className="w-full md:w-6/12">
                        <label className="font-primary-medium text-gray-900">
                          Preparation time
                        </label>
                        <div className="w-full">
                          <Select
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderRadius: "10px",
                                borderColor: "#e7e7e7",
                                cursor: "pointer",
                                "&:hover": {
                                  borderColor: "#e7e7e7",
                                },
                              }),
                            }}
                            className="w-full my-1"
                            isSearchable={false}
                            defaultValue={productData?.preparationTime?.id}
                            options={preparationTime}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end gap-4 items-end text-center my-4 md:my-8   *:py-1 *:px-2 *:md:px-4 *:rounded-lg text-white *:font-primary-bold *:text-sm *:md:text-base">
                      <button
                        onClick={closeModal}
                        className="bg-gray-800 hover:bg-gray-700"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-green-500 hover:bg-green-600 "
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
                </Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>

      )}
    </>
  );
};

export default ProductEditForm;
