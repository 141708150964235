import {OrderList} from "../elements/OrderList";
import {RecentOrdersSection} from "../elements/RecentOrdersSection";

export const HomePage = () => {
    return (
        <>
            <div className={"w-10/12 mx-auto flex justify-between items-center border-b border-gray-200 py-2 mt-10"}>
                <h3 className={"text-xl font-semibold text-primary"}>Ongoing Orders</h3>
                <div>
                    <button
                        className={"text-sm border border-primary text-primary font-normal py-1 px-3 rounded-lg hover:bg-blue-100"}>+
                        Place New Order
                    </button>
                </div>
            </div>
            <div className={"w-11/12 md:w-10/12 md:flex gap-5 mx-auto my-5"}>
                <OrderList/>
                <RecentOrdersSection/>
            </div>

        </>
    )
}
