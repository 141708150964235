import React, {useEffect, useState} from 'react';
import SuccessIcon from '../../../utils/icons/SuccessIcon';
import CloseIcon from '../../../utils/icons/CloseIcon';
import InfoIcon from '../../../utils/icons/InfoIcon'; // Import your InfoIcon
import WarningIcon from '../../../utils/icons/WarningIcon'; // Import your WarningIcon
import ErrorIcon from '../../../utils/icons/ErrorIcon'; // Import your ErrorIcon
import {AnimatePresence, motion} from 'framer-motion';

export const Alert = ({ variant = "success", message, className = "", onClose, visibility }) => { // Added onClose for the close button

    useEffect(() => {
        const timer = setTimeout(onClose, 5000);

        return () => clearTimeout(timer);
    }, [visibility, onClose]);

    const alertVariants = {
        success: {
            border: 'border-green-500',
            text: 'text-green-800',
            shadow: 'shadow-green-50',
            icon: <SuccessIcon className="w-5 h-5 text-green-500 fill-current" />,
            title: 'Success',
        },
        error: {
            border: 'border-red-500',
            text: 'text-red-800',
            shadow: 'shadow-red-50',
            icon: <ErrorIcon className="w-5 h-5 text-red-500 fill-current" />, // Use ErrorIcon
            title: 'Error',
        },
        warning: {
            border: 'border-yellow-500',
            text: 'text-yellow-800',
            shadow: 'shadow-yellow-50',
            icon: <WarningIcon className="w-5 h-5 text-yellow-500 fill-current" />, // Use WarningIcon
            title: 'Warning',
        },
        info: {
            border: 'border-blue-500',
            text: 'text-blue-800',
            shadow: 'shadow-blue-50',
            icon: <InfoIcon className="w-5 h-5 text-blue-500 fill-current" />, // Use InfoIcon
            title: 'Info',
        },
    };

    const currentVariant = alertVariants[variant] || alertVariants.success; // Default to success if variant is invalid
    const handleClose =()=>{
        onClose();
    }
    return (
        <AnimatePresence>
            {visibility && (
                <div className={'fixed top-0 z-[9999] w-full h-screen'}>
                    <motion.div
                        className={`sticky top-32 mx-auto border-r border-r-gray-300 border-t border-t-gray-300 border-b border-b-gray-300 bg-white md:w-4/12 p-4 border-l-[6px] ${currentVariant.border} rounded-xl ${currentVariant.text} shadow-md ${currentVariant.shadow} ${className}`} // Styles
                        initial={{opacity: 0, y: -20}} // Entry animation
                        animate={{opacity: 1, y: 0}} // Exit animation
                        exit={{opacity: 0, y: -20}} // Transition settings
                        transition={{duration: 0.3, ease: "easeInOut"}}
                    >
                        <div className="flex justify-between">
                            <div className={`flex gap-2 w-full ${message ? 'items-start' : 'items-center'}`}>
                                <div className={`py-1`}>
                                    {currentVariant.icon}
                                </div>
                                <div>
                                    <p className="font-medium">{currentVariant.title}</p>
                                    {message && <p className="text-sm mt-2 text-gray-500 w-11/12">{message}</p>}
                                </div>
                            </div>
                            <div onClick={handleClose}>
                                <CloseIcon className="w-6 h-6 text-gray-400 fill-current cursor-pointer"/>
                            </div>
                        </div>
                    </motion.div>
                </div>
            )}
        </AnimatePresence>
    );
};