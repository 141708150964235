import React, { useEffect, useState } from "react";
import FilterIcon from "../../utils/icons/FilterIcon";
import Select from "react-select";
import CONFIG from "../../utils/Config";
import axios from "axios";
import { useAuth } from "../../utils/authentication/auth";
import { useClient } from "../../utils/context/ClientContext";
import {Dropdown} from "./inputs/Dropdown";

function Filter(props) {
  const auth = useAuth();
  const clientContext = useClient();
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [preparationTime, setPreparationTime] = useState([]);
  const handleCategory = (value) => {
    setSelectedCategories(value);
    props.handleProductFilter(1, value);
  };
  const handleSubCategory = (prop) => {
    props.handleProductFilter(2, prop);
  };
  const handlePreparationTimeFilter = (prop) => {
    console.log(prop);
    props.handleProductFilter(3, prop);
  };
  useEffect(() => {
    axios({
      url: CONFIG.URL + "public/product/getCategories",
      method: "GET",
      headers: {
        'X-TenantID': clientContext.tenantID,
        Authorization: auth.authToken
      },
    })
      .then((res) => {
        if (res.data.length > 0) {
          let options = [];
          res.data.map((e) => {
            let option = {
              value: e.id,
              label: e.name,
            };
            options.push(option);
          });
          if (options.length > 0) {
            setCategories(options);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
    axios({
      url: CONFIG.URL + "public/product/getSubCategories",
      method: "GET",
      headers: {    
        Authorization: auth.authToken,
        'X-TenantID': clientContext.tenantID
      },
    })
      .then((res) => {
        if (res.data.length > 0) {
          let options = [];
          res.data.map((e) => {
            let option = {
              value: e.id,
              label: e.label,
            };
            options.push(option);
          });
          if (options.length > 0) {
            setSubCategories(options);
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
    axios({
      url: CONFIG.URL + "public/product/getPreparationTime",
      method: "GET",
      headers: {
        'X-TenantID': clientContext.tenantID,
        Authorization: auth.authToken        
      },
    })
      .then((res) => {
        if (res.data.length > 0) {
          let options = [];
          res.data.map((e) => {
            let option = {
              value: e.id,
              label: e.label,
            };
            options.push(option);
          });
          if (options.length > 0) setPreparationTime(options);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <>
      <div className="flex gap-4 items-center justify-start mt-4">
        <div className="text-gray-800 border border-gray-200 p-2 flex items-center rounded-2xl gap-1.5  cursor-pointer w-fit">
          <FilterIcon className="w-5 h-5" />
          <h2 className="font-primary-medium text-sm md:text-base ">Filters</h2>
        </div>
        <div>
          <Dropdown options={categories} value={selectedCategories} setValue={handleCategory} multiSelect={true} label={"Categories"}/>
        </div>
        <div>
          <Dropdown options={subCategories} value={subCategories} setValue={handleSubCategory} multiSelect={true} label={"Sub Categories"}/>
        </div>
        <div>
          <Dropdown options={preparationTime} value={subCategories} setValue={handlePreparationTimeFilter} multiSelect={true} label={"Preparation Time"}/>
        </div>
      </div>
    </>
  );
}

export default Filter;
