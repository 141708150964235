import OrderCard from "./OrderCard";
import { useEffect, useState } from "react";
import {
    APPLICATION_ENDPOINTS,
    AUTH_TYPE,
    fetchData,
    METHOD_TYPE,
} from "../../utils/RequestUtils";
import { useClient } from "../../utils/context/ClientContext";

export const OrderList = () => {
    const { tenantID } = useClient();
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const [error, setError] = useState(null); // Track error state

    useEffect(() => {
        const fetchActiveOrders = async () => {
            setIsLoading(true); // Set loading state to true
            setError(null); // Clear any previous errors

            try {
                const response = await fetchData(
                    APPLICATION_ENDPOINTS.GET_ACTIVE_ORDERS,
                    undefined, // No additional data for GET request
                    METHOD_TYPE.GET,
                    AUTH_TYPE.AUTHENTICATED,
                    tenantID
                );
                setOrders(response.data);
            } catch (error) {
                console.error("Error fetching orders:", error);
                setError(error); // Store error for potential display
            } finally {
                setIsLoading(false); // Set loading state to false after fetching/error
            }
        };

        fetchActiveOrders();
    }, [tenantID]); // Re-fetch on tenantID change

    return (
        <>
            <div className="w-full md:w-7/12 lg:w-9/12 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 border border-gray-200 rounded-lg shadow-lg p-4">
                {isLoading ? (
                    // Display loading indicator while data is being fetched
                    <p>Loading orders...</p>
                ) : error ? (
                    // Display error message if fetching failed
                    <p>Error fetching orders: {error.message}</p>
                ) : orders.length > 0 ? (
                    orders.map((data) => (
                        <OrderCard orderData={data} key={data.orderId} />
                    ))
                ) : (
                    // Display message if no active orders found
                    <p>No active orders found.</p>
                )}
            </div>
        </>
    );
};