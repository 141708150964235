import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Sheet} from "react-modal-sheet";
import CloseIcon from "../../utils/icons/CloseIcon";

const OrderMenuList = ({ orderData, isModalOpen, handleVisibility }) => {
    const closeModal = () => {
        handleVisibility(false);
    };

    return (
        <>
            {isModalOpen && (
                <Sheet isOpen={isModalOpen} onClose={closeModal} className="md:w-6/12 mx-auto">
                    <Sheet.Container>
                        <Sheet.Header className="flex justify-between items-center p-2">
                            <div className={'font-bold text-xl text-gray-700 pt-5 pl-5'}>
                                Order Details
                            </div>
                            <button onClick={closeModal}>
                                <CloseIcon className="w-8 h-8 text-gray-600 hover:text-gray-800 hover:bg-gray-100 p-1 rounded-md" />
                            </button>
                        </Sheet.Header>

                        <Sheet.Content>
                            <Sheet.Scroller>
                                <TableContainer component={Paper} className="mt-4" style={{ width: 'calc(100% - 28px)', margin: '16px',  border: 'none', boxShadow: 'none' }} sx={{border: 'none'}}> {/* Adjusted width and margin */}
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="font-semibold text-gray-700">Image</TableCell>
                                                <TableCell className="font-semibold text-gray-700">Product Name</TableCell>
                                                <TableCell className="font-semibold text-gray-700">Quantity</TableCell>
                                                <TableCell className="font-semibold text-gray-700">Amount</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {orderData?.bill?.billDetails.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <img src={item.product.imageUrl} alt={item.product.name} className="w-12 h-12 rounded" />
                                                    </TableCell>
                                                    <TableCell>{item.product.name}</TableCell>
                                                    <TableCell>{item.quantity}</TableCell>
                                                    <TableCell>₹{item.amount.toFixed(2)}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell colSpan={2} /> {/* Empty cells for spacing */}
                                                <TableCell className="font-bold">
                                                    Total
                                                </TableCell>
                                                <TableCell className="font-bold">
                                                   ₹{orderData.amount.toFixed(2)}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Sheet.Scroller>
                        </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop onClick={closeModal} />
                </Sheet>
            )}
        </>
    );
};

export default OrderMenuList;