import React, {useState} from 'react';

const Tabs = ({ content, defaultSelectedTab }) => {
    const [ currentTab, setCurrentTab ] = useState(defaultSelectedTab);
    const handleTabSwitch = (tabId) => {
        setCurrentTab(tabId)
    }
    return (
        <div>
            <div className={'flex gap-5 justify-start items-center p-2 border-gray-200 py-4 no-select cursor-pointer bg-blue-50 w-full'}>
                {
                    content && content.map(({ value, header }, index) => {
                        return(
                            <>
                                <div
                                    className={`${(currentTab === value) && 'border-b-2 border-primary text-primary '} px-3`}
                                    onClick={() => handleTabSwitch(value)}>
                                    {header}
                                </div>
                            </>

                        )
                    })
                }
            </div>
            <div>
                {
                    content && content.map(({ value, content }, index) => {
                        return(
                            <>
                                <div className={`${(currentTab === value) ?  'block' : 'hidden'}`}>
                                    {content}
                                </div>
                            </>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default Tabs;