import React from 'react'

function CloseIcon({width, height, className}) {
  return (
      <svg width={width} height={height} className={className} viewBox="0 0 50 50" fill="none"
           xmlns="http://www.w3.org/2000/svg">
              <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"
                    d="M14.5 8a19 19 0 0 0-4.75 3.84C6.794 15.146 5 19.49 5 24.246C5 34.603 13.507 43 24 43s19-8.397 19-18.754c0-4.757-1.794-9.1-4.75-12.406A19 19 0 0 0 33.5 8M24 4v20"/>
      </svg>
  )
}

export default CloseIcon