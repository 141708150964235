import React from 'react'
import { motion } from "framer-motion";
import OfferList from '../elements/OfferList';
import Footer from '../elements/Footer';

export default function ViewOffersPage() {
  return (
    <div className="h-lvh grid grid-cols-1 content-between">
        <motion.div animate={{ y: -10 }} transition={{ duration: 0.5 }}>
          <OfferList/>
        </motion.div>
        <Footer />
      </div>
  )
}
