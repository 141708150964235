const CONFIG = {
    // URL:"https://cle-factura-image-hub-429893815831.asia-south1.run.app/CleFactura/",
    URL:"https://192.168.1.12/CleFactura/",
    TENANT_ID:"defaultdb"

    // KEY: "78b848c8-5b80-4205-a6c6-2eed8bc6c8a7",
    // TOKEN: "9c11bf45-914e-403d-be4f-c9f37e4eb6df",
    // // // URL:"http://192.168.43.51/CleFactura/",
    // URL:"http://192.168.1.4/CleFactura/"
}
export default CONFIG
