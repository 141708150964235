import React from 'react'
import { useAuth } from './auth'
import { Navigate } from 'react-router-dom';

const RequireAuth = ({ children }) => {
    const auth = useAuth();   
    if(!auth.userData){              
        return <Navigate to='/login'/>;
    }
  return children;
}

export default RequireAuth