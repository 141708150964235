import React, { useState } from "react";
import { motion } from "framer-motion";

function TextInput({ label, name, value, required, handleChange, className = "" }) {
    const [focused, setFocused] = useState(false);

    const isLabelVisible = focused || value;

    return (
        <div
            className={`p-3 border w-52 rounded-lg relative ${
                focused ? "border-primary" : "border-gray-300"
            } ${className}`}
        >
            {isLabelVisible && (
                <motion.div
                    className="bg-white text-xs font-medium text-primary px-1 absolute -top-2.5 left-2.5"
                    initial={{ y: -10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.3, type: "spring", stiffness: 100, damping: 15 }}
                >
                    {label}
                </motion.div>
            )}
            <input
                id={name}
                type="text"
                placeholder={!isLabelVisible ? label : "" }
                value={value}
                name={name}
                required={required}
                onChange={handleChange}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                className="w-full bg-transparent outline-none "
            />
        </div>
    );
}

export default TextInput;
