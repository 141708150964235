import React from 'react'
import { motion } from 'framer-motion'
import AddUserForm from '../elements/AddUserForm'
import Footer from '../elements/Footer'

function AddUserPage() {
  return (
    <div className="h-lvh grid grid-cols-1 content-between">
      <motion.div animate={{ y: -10 }} transition={{ duration: 0.5 }}>
        <AddUserForm/>
      </motion.div>
      <Footer />
    </div>
  )
}

export default AddUserPage