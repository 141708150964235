import { Tooltip } from '@mui/material';
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from 'react';
import userIcon from '../../assets/images/USER_ICON.png';
import { useAuth } from '../../utils/authentication/auth';
import icons from '../../utils/Icons';
import SupportIcon from "../../utils/icons/SupportIcon";
import LogoutIcon from "../../utils/icons/LogoutIcon";

export const Profile = ({ handleOpen }) => {
    const [profileOpened, setProfileOpened] = useState(false);
    const auth = useAuth();
    const wrapperRef = useRef(null);

    const openProfileData = () => setProfileOpened(!profileOpened);

    const handleSignOut = () => handleOpen();

    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setProfileOpened(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []); // Ensures event listener is added only once on mount

    return (
        <div className='relative' onClick={openProfileData}>
                <div className={"flex items-center gap-4 cursor-pointer"}>
                    <div className={"px-4 py-2 border-r border-gray-200 text-center"}>
                        <div className={"flex items-center gap-2"}>
                            <SupportIcon className={"w-6 h-6 cursor-pointer"}/>
                            <div>
                                <p className={"text-xs md:text-sm font-regular cursor-pointer text-gray-600"}>Contact Support</p>
                                <p className={"text-sm md:text-base font-medium text-primary cursor-pointer"}>90037867190</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center gap-2 rounded-xl">
                        <img src={userIcon} alt="User Icon" className='w-10 h-10 cursor-pointer'/>
                        {window.innerWidth >= 768 && (
                            <>
                                <div>
                                    <p className={"font-primary-medium cursor-pointer text-primary"}>{auth?.userData?.fullName}</p>
                                    <p className={"text-xs font-primary-regular cursor-pointer text-gray-600"}>Administrator</p>
                                </div>
                                <LogoutIcon className={"w-6 h-6 text-red-500 ml-2 cursor-pointer"} onClick={handleSignOut}/>
                            </>
                        )
                    }
                </div>
        </div>
    <motion.div
        initial={{scale: 0, opacity: 0}}
                animate={profileOpened ? {scale: 1, opacity: 1} : {scale: 0, opacity: 0}}
                transition={{type: "spring", stiffness: 500, damping: 30}}
                className='text-nowrap w-60 h-fit font-primary-regular flex flex-col items-start justify-start gap-1 bg-white rounded-xl px-6 py-4 absolute border top-16 right-0 shadow-lg'
                ref={wrapperRef}
            >
                <div className='flex justify-start items-center gap-1'>
                    <img src={userIcon} alt="User Icon" className='w-10 h-10 mx-auto' />
                    <div className='flex flex-col items-start'>
                        <span className='font-primary-semibold text-center text-lg'>
                            {auth?.userData?.fullName || 'Guest'}
                        </span>
                        <span className='text-center text-sm'>Administrator</span>
                    </div>
                </div>
                <div className='bg-gray-400 w-11/12 mx-auto h-[1px] mt-4'></div>
                <div className='w-11/12 mx-auto text-sm *:cursor-pointer'>
                    <p className='py-3 border-b border-gray-200'>Profile Settings</p>
                    <p className='py-3'>Tforo Support</p>
                </div>
                <button
                    className="bg-gray-300 font-primary-medium text-gray-800 px-3 py-1 rounded-md text-center justify-center w-full flex items-center gap-3 cursor-pointer hover:bg-gray-800 hover:text-white"
                    onClick={handleSignOut}
                >
                    <span>{icons.logout}</span>
                    <span>Signout</span>
                </button>
            </motion.div>
        </div>
    );
};
