import React from 'react'

function DownArrowIcon(props) {
  return (    
    <svg width={props.width} height={props.width} className={props.className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="m6 9l6 6l6-6"
      />
    </svg>
  )
}

export default DownArrowIcon;