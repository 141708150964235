import React, {useEffect, createRef, useRef, useState} from 'react';
import CONFIG from '../../utils/Config';
import {useClient} from '../../utils/context/ClientContext';
import {useAuth} from '../../utils/authentication/auth'
import FileUploadSection from './FileUploadSection';
import ProductTable from './TableComponent';
import Select from '../elements/inputs/Select';
import constants from '../../utils/Constants';
import ImagePlaceholder from '../../assets/images/image_placeholder.svg'
import EditIcon from '../../utils/icons/Edit';
import axios from 'axios';
import TextInput from './inputs/TextInput';
import {Dropdown} from "./inputs/Dropdown";
import SecurityIcon from "../../utils/icons/SecurityIcon";
import ReviewIcon from "../../utils/icons/ReviewIcon";
import {APPLICATION_ENDPOINTS, AUTH_TYPE, fetchData, METHOD_TYPE} from "../../utils/RequestUtils";
import {Alert} from "./ui/Alert";
import Button from "./inputs/Button";

const ProductForm = () => {
    const clientContext = useClient();
    const auth = useAuth();
    const [productName, setProductName] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productPrice, setProductPrice] = useState("");
    const [productQty, setProductQty] = useState("");
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [preparationTime, setPreparationTime] = useState();
    const [productImage, setProductImage] = useState(true);
    const imageRef = React.createRef();
    const [imageSrc, setImageSrc] = useState(null);
    const [categories, setCategories] = useState();
    const [subCategories, setSubCategories] = useState();
    const [preparationTimes, setPreparationTimes] = useState();
    const [ variant, setVariant ] = useState('success');

    const [errors, setErrors] = useState({
        productName: '',
        productDescription: '',
        productPrice: '',
        category: '',
        subCategory: '',
        preparationTime: '',
    });
    useEffect(() => {
        axios({
            url: CONFIG.URL + "public/product/getCategories",
            method: "GET",
            headers: {
                'X-TenantID': clientContext.tenantID,
                Authorization: auth.authToken
            }
        }).then((res) => {
            if (res.data.length > 0) {
                let options = [];
                res.data.map((e) => {
                    let option = {
                        value: e.id,
                        label: e.name
                    }
                    options.push(option);
                });
                if (options.length > 0) {
                    setCategories(options)
                }
            }
        }).catch((err) => {
            console.error(err);
        });


        axios({
            url: CONFIG.URL + "public/product/getSubCategories",
            method: "GET",
            headers: {
                'X-TenantID': clientContext.tenantID,
                Authorization: auth.authToken
            }
        }).then((res) => {
            if (res.data.length > 0) {
                let options = [];
                res.data.map((e) => {
                    let option = {
                        value: e.id,
                        label: e.label
                    }
                    options.push(option);
                });
                if (options.length > 0) {
                    setSubCategories(options)
                }
            }
        }).catch((err) => {
            console.error(err);
        });
        axios({
            url: CONFIG.URL + "public/product/getSubCategories",
            method: "GET",
            headers: {
                'X-TenantID': clientContext.tenantID,
                Authorization: auth.authToken
            }
        }).then((res) => {
            if (res.data.length > 0) {
                let options = [];
                res.data.map((e) => {
                    let option = {
                        value: e.id,
                        label: e.label
                    }
                    options.push(option);
                });
                if (options.length > 0) {
                    setSubCategories(options)
                }
            }
        }).catch((err) => {
            console.error(err);
        });
        axios({
            url: CONFIG.URL + "public/product/getPreparationTime",
            method: "GET",
            headers: {
                'X-TenantID': clientContext.tenantID,
                Authorization: auth.authToken
            },
        }).then((res) => {
            if (res.data.length > 0) {
                let options = [];
                res.data.map((e) => {
                    let option = {
                        value: e.id,
                        label: e.label
                    }
                    options.push(option);
                })
                if (options.length > 0)
                    setPreparationTimes(options);
            }
        }).catch((err) => {
            console.error(err);
        });
    }, []);

    const [submitted, setSubmitted] = useState(false);
    const [products, setProducts] = useState(null);
    const [columns] = useState([
        {field: "name", headerName: "Name"},
        {field: "description", headerName: "Description"},
        {field: "rate", headerName: "Rate"},
        {field: "qty", headerName: "Quantity"},
        {field: "category", headerName: "Category"}
    ]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        switch (name) {
            case 'productName':
                setProductName(value);
                break;
            case 'productDescription':
                setProductDescription(value);
                break;
            case 'productPrice':
                setProductPrice(value);
                break;
            case 'productQty':
                setProductQty(value);
                break;
            case 'category':
                setCategory(value);
                break;
            case 'subCategory':
                setSubCategory(value);
                break;
            case 'preparationTime':
                setPreparationTime(value);
                break;
            default:
                break;
        }
        setErrors(prevErrors => ({...prevErrors, [name]: value === null ? `${name} is required` : ''}));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
      console.log("hello");

        const errors = {
            productName: productName.trim() === '' ? 'Product Name is required' : '',
            productDescription: productDescription.trim() === '' ? 'Product Description is required' : '',
            productPrice: productPrice === null ? 'Product Price is required' : '',
            productQty: productQty === null ? 'Quantity is required' : ''
        };

        if (Object.values(errors).some((error) => error !== '')) {
            setErrors({...errors});
            setSubmitted(false);
        } else {
            let productArray = products || [];
            const product = {
                name: productName,
                description: productDescription,
                rate: productPrice,
                qty: productQty,
                category: {
                    id: category,
                },
                imageUrl: "https://buffer.com/cdn-cgi/image/w=1000,fit=contain,q=90,f=auto/library/content/images/size/w1200/2023/10/free-images.jpg"
            };

            productArray.push(product);
            setProducts(productArray);
            fetchData(APPLICATION_ENDPOINTS.SAVE_PRODUCT, undefined, METHOD_TYPE.POST, AUTH_TYPE.AUTHENTICATED, clientContext.tenantID, 'json', products)
                .then((res) => {
                  setSubmitted(true);
                  setErrors({
                    productName: '',
                    productDescription: '',
                    productPrice: '',
                    productQty: '',
                    productCategory: '',
                  });
                })
                .catch((err) => {
                  setVariant('error')
                  setSubmitted(true);
                  console.log(err);
                })
        }
    };

    const handleItemRemoval = (id) => {
        let productList = products;
        productList = productList.filter((e) => {

        });
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 0) {
            setProductImage(files[0]);
            setImageSrc(URL.createObjectURL(files[0]));
        }
    }


    return (
        <div>
            <div>
              <Alert
                  visibility={submitted}
                  onClose = {() => setSubmitted(false)}
                  variant={variant}
                  message={"Product added successfully! and the review usually takes 1 - 2 business days to approve if emergency contact support"}/>

            </div>
            <div
                className="flex flex-col gap-3 mt-5 md:mt-10 md:mb-20 p-5 md:p-10 w-full md:w-8/12 lg:w-6/12 max-w-7xl mx-auto shadow-md rounded-2xl md:border">
                <h3 className="text-xl font-medium text-gray-800">
                    Add New Product(s)
                </h3>
                <FileUploadSection/>
                <div className="relative flex pt-5 items-center">
                    <div className="flex-grow border-t border-gray-400"></div>
                    <span className="flex-shrink mx-4 text-gray-700 font-primary-semibold">Or</span>
                    <div className="flex-grow border-t border-gray-400"></div>
                </div>
                <form onSubmit={handleSubmit} className={'flex flex-col gap-6'}>
                    <div className='relative w-fit'>
                        {!imageSrc ? (<img
                            src={ImagePlaceholder}
                            alt=""
                            className="w-24 md:w-32 h-24 md:h-32 rounded-full  object-bottom "
                        />) : (<img
                            src={imageSrc}
                            alt=""
                            className="w-24 md:w-32 h-24 md:h-32 rounded-full  object-bottom "
                        />)
                        }

                        <input
                            type="file"
                            id="imageUpload"
                            accept="image/*"
                            hidden
                            ref={imageRef}
                            onChange={handleImageChange}
                        />
                        <div className="absolute bottom-0 -right-3 cursor-pointer  ">
                            <div onClick={() => imageRef.current.click()}
                                 className='flex gap-0.25 font-primary-semibold items-center py-0.25 px-1 bg-white rounded border border-gray-300'>
                                <EditIcon className="w-3 h-3 text-gray-800 fill-current opacity-100"/>&nbsp;<span
                                className='text-xs'>Edit</span></div>
                        </div>
                    </div>
                    <div>
                        <TextInput label={"Name"} name={"productName"} value={productName} required={true}
                                   handleChange={handleChange} className={"w-full"}/>
                        {errors.productName && <p className="form-error-msg">{errors.productName}</p>}
                    </div>
                    <div>
                        <TextInput label={"Description"} name={"productDescription"} value={productDescription}
                                   required={true}
                                   handleChange={handleChange} className={"w-full"}/>
                        {errors.productDescription && <p className="form-error-msg">{errors.productDescription}</p>}
                    </div>
                    <div className={'flex justify-between gap-4'}>
                        <div className='w-6/12'>
                            <TextInput label={"Price in ₹"} name={"productPrice"} value={productPrice} required={true}
                                       handleChange={handleChange} className={"w-full"}/>
                            {errors.productPrice && <p className="form-error-msg">{errors.productPrice}</p>}
                        </div>
                        <div className='w-6/12'>
                            <TextInput label={"Qty"} name={"productQty"} value={productQty} required={true}
                                       handleChange={handleChange} className={"w-full"}/>
                            {errors.productQty && <p className="form-error-msg">{errors.productQty}</p>}
                        </div>
                    </div>
                    <div className={"w-full"}>
                        <Dropdown options={categories} setValue={setCategory}
                                  label={"Categories"} className={"w-full"}/>
                        {errors.category && <p className="form-error-msg">{errors.category}</p>}
                    </div>
                    <div className={"w-full"}>
                        <Dropdown options={subCategories} setValue={setSubCategory}
                                  label={"Sub Categories"} className={"w-full"}/>
                        {errors.subCategory && <p className="form-error-msg">{errors.subCategory}</p>}
                    </div>
                    <div className={"w-full"}>
                        <Dropdown options={preparationTimes} setValue={setPreparationTime} label={"Preparation Time"}
                                  className={"w-full"}/>
                        {errors.preparationTime && <p className="form-error-msg">{errors.preparationTime}</p>}
                    </div>
                    <div className="flex items-center justify-end gap-3 w-full ">
                        <Button variant={'text'} label={'Cancel'} className={'w-24'}/>
                        <Button variant={'filled'} label={'Submit for Review'} className={'w-24'} type={'submit'}/>
                    </div>
                    <div className="flex justify-center  items-start w-9/12 mx-auto my-8">
                        <ReviewIcon className="w-5 h-5 text-primary "/>
                        <h1 className="text-xs font-primary-medium text-gray-500 text-center">
                            Usually review might take upto 1 - 2 working days for approval. If its business critical
                            feel free to contact us at
                            {" "}<a href="mailto:reviews@tforo.com"
                                    className="underline text-sky-800">reviews@tforo.com</a>
                        </h1>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProductForm;
