import React, { Component } from 'react'
import Search from '../../utils/icons/Search';

export default class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchClicked: props.isSearchClicked
    }
  }
  handleSearch = (e) => {
    if (!this.props.isHome)
      this.props.handleSearch(e.target.value);
  }
  handleRedirect = () => {
    if (this.props.isHome) {
      window.location = "/search";
    }
  }
  render() {
    return (
      <div className="w-full relative">
        <input
          type="text"
          className="w-full font-primary-regular h-12 border-2 text-base rounded-lg relative pl-10 outline-none appearance-none "
          title="search box"
          placeholder="Search Here"
          onClick={this.handleRedirect}
          onChange={this.handleSearch}
        />
        <Search className="w-10 h-10 absolute top-0  mt-1 text-center px-2 py-1" />
      </div>
    );
  }
}
