import React, { useEffect, useRef, useState } from 'react'
import HambergerIcon from '../../utils/icons/HambergerIcon'
import CloseIcon from '../../utils/icons/CloseIcon'
import LOGO from '../../assets/images/CleFactura.png'
import { NavLink } from 'react-router-dom';
import HomeIcon from '../../utils/icons/HomeIcon';
import ContactIcon from '../../utils/icons/ContactIcon';
import OfferIcon from '../../utils/icons/OfferIcon';
import TableIcon from '../../utils/icons/TableIcon';
import ProductIcon from '../../utils/icons/ProductIcon';
import DownArrowIcon from '../../utils/icons/DownArrowIcon';
import { motion } from "framer-motion";
import UserIcon from '../../utils/icons/UserIcon';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import DashboardIcon from "../../utils/icons/DashboardIcon";
import SupportIcon from "../../utils/icons/SupportIcon";

export const MenubarVertical = () => {
    const [menuOpen, setOpenMenu] = useState(false);
    const [activeLink, setActiveLink] = useState();
    const slideNavbar = useRef();


    const menuList = [
        { label: "Home", icon: <HomeIcon className="w-5 h-5 text-gray-800" />, link: "/home" },
        { label: "Dashboard", icon: <DashboardIcon className="w-5 h-5 text-gray-800" />, link: "/dashboard" },
        {
            label: "Products",
            icon: <ProductIcon className="w-5 h-5 text-gray-800" />,
            link: "/products",
        },
        {
            label: "Offers",
            icon: <OfferIcon className="w-5 h-5 text-gray-800" />,
            link: "/viewOffers",
        },
        { label: "Table", icon: <TableIcon className="w-5 h-5 text-gray-800" />, link: "/registerTable" },
        {
            label: "IAM",
            icon: <UserIcon className="w-5 h-5 text-gray-800" />,
            link:"/iam/manageUser",
        },
        { label: "Support", icon: <SupportIcon className="w-5 h-5 text-gray-800" />, link: "/contactUs" }
    ]
    const menuContent = [
        { label: "Terms & Service", link: "https://tforte.in/terms" },
        { label: "Cancellation Policy", link: "https://tforte.in/privacy" },
        { label: "Privacy & Policy", link: "https://tforte.in/privacy" },
        { label: "Contact Us", link: "mailto: hello@clefactura.com" }
    ]
    const handleMenuOpen = () => {
        setOpenMenu(!menuOpen)
    }
    const handleSubMenu = (e) => {
        let classlist = e.currentTarget.lastChild.classList.toString();
        if (classlist.indexOf("hidden") > -1) {
            e.currentTarget.lastChild.classList = classlist.replaceAll("hidden", "");
        } else {
            e.currentTarget.lastChild.classList = classlist + " hidden ";
        }

    }
    const handleActive = (link) => {
        setActiveLink(link)
        handleMenuOpen();
    }

    return (
        <div className='flex items-center gap-7'>
            {menuOpen && <div className={"absolute top-0 left-0 z-30 backdrop-blur-0 bg-black/5 w-full h-screen"} onClick={handleMenuOpen}></div>}
            <div className={"z-30"}>
                <motion.div
                    ref={slideNavbar}
                    initial={{ opacity: 1, x: 3 }}
                    animate={{ opacity: 1, x: menuOpen ? 0 : -10 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                    onMouseEnter={() => setOpenMenu(true)}
                    onMouseLeave={() => setOpenMenu(false)}
                    className={`absolute left-0 top-[120px] ${menuOpen ? "w-2/12" : "w-fit"} py-16 px-5 
                        border border-gray-300 bg-white
                        shadow-2xl p-4 rounded-tr-2xl rounded-br-2xl h-fit
                        transition-all duration-300 ease-in-out`}>
                    <div
                        className='w-full mx-auto md:min-h-fit flex flex-col justify-between md:bg-none rounded-2xl  md:rounded-none shadow md:shadow-none'>
                        <div>
                            {menuList.map((menu, index) => (<div key={index} className='*:font-primary-bold'>
                                {menu.submenu ? (
                                    <div className="" onClick={handleSubMenu}>
                                        <div
                                            className={`flex items-center gap-3 border-gray-100 cursor-pointer border-b h-12`}>
                                            <div>{menu.icon}</div>
                                            <motion.div
                                                initial={{ opacity: 0, x: menuOpen ? 0 : 10 }}
                                                animate={{ opacity: 1, x: menuOpen ? 0 : 0 }}
                                                transition={{ duration: 0.3, ease: "easeInOut" }}
                                                className={`${menuOpen ? "block" : "hidden"}`}>
                                                {menu.label}
                                            </motion.div>
                                        </div>
                                    </div>
                                ) : (
                                    <NavLink to={menu.link}
                                             className={`${activeLink === menu.link ? 'text-primary' : 'text-gray-800'}`}
                                             onClick={() => handleActive(menu.link)}>
                                        <div
                                            className={`flex gap-3 items-center border-b border-gray-100 h-12 cursor-pointer`}>
                                            <p>{menu.icon}</p>
                                            <motion.div
                                                initial={{ opacity: 0, x: menuOpen ? 0 : 10 }}
                                                animate={{ opacity: 1, x: menuOpen ? 0 : 0 }}
                                                transition={{ duration: 0.3, ease: "easeInOut" }}
                                                className={`${menuOpen ? "block ease-in-out transition-all duration-300" : "hidden"}`}>
                                                {menu.label}
                                            </motion.div>
                                        </div>
                                    </NavLink>
                                )}
                            </div>))}
                        </div>
                    </div>
                    <div className='w-full md:hidden mx-auto py-2 px-3 bg-white rounded-2xl shadow my-5'>
                        {menuContent.map((menu, index) => (
                            <NavLink key={index} onClick={() => window.location = menu.link}>
                                <div className=' py-2 px-3'>
                                    <span className='text-gray-800'>{menu.label}</span>
                                </div>
                            </NavLink>))}
                    </div>
                    <div class="flex place-self-end gap-2.5 mt-20 items-center border-t border-gray-200 py-5 md:hidden">
                        <img src={LOGO} alt="Clè-Factura Logo" className="footer-logo  cursor-pointer"
                             onClick={() => window.location = "https://clefactura.tforte.in"}/>
                        <div className='flex flex-col justify-between items-start'>
                            <div class="flex">
                                <h2 class="text-lg font-primary-semibold">Clè-Factura</h2>
                                <sup class="uppercase text-xs font-primary-semibold"
                                >TM</sup
                                >
                            </div>
                            <p class="text-gray-600 font-primary-light text-xs">Your companion in scaling your
                                Business</p>
                        </div>
                    </div>
                </motion.div>

            </div>
        </div>
    )
}