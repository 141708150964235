import React from 'react'

function CloseIcon({width, height, className}) {
  return (    
    <svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="currentColor" d="m12 12.727l-3.592 3.592q-.16.16-.354.15T7.7 16.3t-.16-.363t.16-.364L11.273 12L7.681 8.433q-.16-.16-.15-.364t.169-.363t.363-.16t.364.16L12 11.298l3.567-3.592q.16-.16.354-.16t.354.16q.165.165.165.366t-.165.36L12.702 12l3.592 3.592q.16.16.16.354t-.16.354q-.165.165-.366.165t-.36-.165z"/>
    </svg>
  )
}

export default CloseIcon