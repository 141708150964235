import React from 'react'
import Footer from '../elements/Footer';
import TableForm from '../elements/TableForm';
import { motion } from 'framer-motion'

function TableRegistrationPage() {
  return (
    <div className="h-lvh grid grid-cols-1 content-between">
      <motion.div animate={{ y: -10 }} transition={{ duration: 0.5 }}>
        <TableForm />
      </motion.div>
      <Footer />
    </div>
  );
}
export default TableRegistrationPage;