import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import LOGO from '../../assets/images/CleFactura.png';
import { useAuth } from "../../utils/authentication/auth";
import { Menubar } from "./Menubar";
import {Profile} from "./Profile";
import {Dialog, Button, DialogActions, DialogTitle, DialogContent, DialogContentText} from "@mui/material";
import {MenubarVertical} from "./MenubarVertical";
import Header from "./ui/Header";

const Navbar = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMd, setIsMd] = useState(window.innerWidth >= 768);
  const [isVisible, setIsVisible] = useState(false);
  const [isRegisterVisible, setIsRegisterVisible] = useState(false);
  const [isOffersVisible, setIsOffersVisible] = useState(false);
  const [isProductsActive, setIsProductsActive] = useState(false);
  const [isOffersActive, setIsOffersActive] = useState(false);
  const wrapperRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);

  const handleLogout = () => {
    auth.logout();
    handleOpen();
  }

  useEffect(() => {
    const updateWindowDimensions = () => {
      setIsMd(window.innerWidth >= 768);
    };

    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsMenuOpen(false);
        setIsVisible(false);
        setIsRegisterVisible(false);
        setIsOffersVisible(false);
      }
    };

    window.addEventListener("resize", updateWindowDimensions);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handledropdown = (type) => {
    switch (type) {
      case 1:
        setIsVisible(!isVisible);
        break;
      case 2:
        setIsRegisterVisible(!isRegisterVisible);
        setIsOffersVisible(false);
        break;
      case 3:
        setIsRegisterVisible(false);
        setIsOffersVisible(!isOffersVisible);
        break;
      default:
        break;
    }
  };

  const handleActiveness = (type) => {
    switch (type) {
      case 1:
        setIsProductsActive(true);
        console.log(isProductsActive);
        break;
      case 2:
        setIsOffersActive(true);
        break;
      default:
        break;
    }
  };

  return (
    location.pathname.includes('login') ? <></> :
      <>
        <Header/>
        <div className="font-primary-regular mx-auto w-full shadow-[0_15px_40px_-20px_rgb(40_44_63_/_15%)] border-b border-[#282c3f0f] bg-white h-20 my-auto ">
          <div className="w-11/12 mx-auto flex justify-between items-center bg-transparent h-full">
            <div className="flex justify-start gap-5 items-center">
              { window.innerWidth >= 768 ? <MenubarVertical /> : <Menubar />}
              <Link to={"/home"}>
                <div className="font-logo">
                  <img
                      src={LOGO}
                      alt="cle-factura logo"
                      className="product-logo"
                  />
                  {/*<p className={"text-2xl  text-white font-medium cursor-pointer"}>Tforo <span className={"text-white font-bold"}>Dine</span></p>*/}

                </div>
                {/*<div className="bg-gradient-to-r from-yellow-900 via-yellow-800 to-yellow-600 px-4 py-2 ">*/}
                {/*  <img*/}
                {/*      src={LOGO}*/}
                {/*      alt="cle-factura logo"*/}
                {/*      className="product-logo"*/}
                {/*  />*/}
                {/*  /!*<p className={"text-2xl  text-white font-medium cursor-pointer"}>Tforo <span className={"text-white font-bold"}>Dine</span></p>*!/*/}

                {/*</div>*/}
              </Link>
            </div>
            <div>
            </div>
            <Profile handleOpen={handleOpen}/>
          </div>
          <Dialog
              open={open}
              onClose={handleOpen}
              aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Are you sure you want to logout?"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText>
                  Your data might be lost and you have to log in again to gain access, If you wish to proceed click confirm, else click cancel.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleOpen}>
                Cancel
              </Button>
              <Button onClick={handleLogout} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
  );
};

export default Navbar;
