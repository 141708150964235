import React, {useState} from "react";
import Star from "../../utils/icons/Star";
import VegIcon from "../../utils/icons/VegIcon";
import NonVegIcon from "../../utils/icons/NonVegIcon";

const MenuItemVertical = ({ product, handleClick, handleDelete, type }) => {

    if (product !== undefined) {
        return (
            <div className={`rounded-2xl shadow-md w-full grow border`}>
                <img
                    src={product.imageUrl || ""}
                    alt=""
                    className="w-full h-32 md:h-48 object-cover rounded-t-2xl"
                />
                <div className={'flex flex-col justify-between px-3 pt-2 pb-4 gap-4 h-fit'}>
                    <div className={'flex flex-col gap-1'}>
                        <p className="text-sm md:text-base font-semibold text-gray-800 overflow-hidden line-clamp-3">
                            {product.name}
                          </p>
                        <div className="flex gap-1 items-center">
            <p className="">
              {product.category.name === "Veg" ? (
                  <VegIcon className="w-5 h-5 text-green-600 fill-current"/>
              ) : (
                  <NonVegIcon className="w-5 h-5 text-red-600 fill-current"/>
              )}
            </p>
                            <small className="text-gray-800 font-bold text-xs ">
                                {product.subCategory.label}
                            </small>
                        </div>
                        <p className="font-medium text-gray-600 text-xs md:text-sm line-clamp-2 overflow-hidden h-8 md:h-10">
                    {product.description}
                </p>
                    </div>
                    <div className={'flex flex-col gap-2'}>
                        <div className="flex justify-between items-center">
                            <p className="font-semibold text-base md:text-lg">
                                ₹ {product.rate}
                            </p>
                        </div>
                        <div className="flex items-center justify-start gap-3">
                            <button className={"bg-primary border text-white px-4 py-1 rounded-lg text-sm w-20"}
                                    onClick={() => handleClick(product.id)}
                                    data-product={product.id}>
                                Edit
                            </button>
                            <button className={"border-gray-700 text-gray-700 border px-4 py-1 rounded-lg text-sm w-20"}
                                    onClick={() => handleDelete(product.id)}>
                                Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default MenuItemVertical;
