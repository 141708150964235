import React from 'react'
import Footer from '../elements/Footer'
import { motion } from "framer-motion";
import ContactForm from '../elements/ContactForm';

export default function ContactUsPage() {
  return (
    <div className=" grid grid-cols-1 content-between">      
        <motion.div animate={{ y: -10 }} transition={{ duration: 0.5 }}>
          <ContactForm/>
        </motion.div>       
        <Footer />
      </div>
  )
}
