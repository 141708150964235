import React, { useState, useRef } from "react";
import axios from "axios";
import CONFIG from "../../utils/Config";

const FileUploadSection = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(1);
  const fileInputRef = useRef();

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      setFile(files[0]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0) {
      setFile(files[0]);
      setUploadStatus(1);
    }
  };

  const handleClear = () => {
    setFile(null);
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    if (file !== undefined && uploadStatus === 1) {
      formData.append("file", file);
      axios({
        url: CONFIG.URL + "product/upload",
        method: "POST",
        headers: {
          key: CONFIG.KEY,
          token: CONFIG.TOKEN,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      })
        .then((response) => {
          alert(response.data.message);
          setUploadStatus(0);
          setFile(null);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div
      className="w-full h-36 md:h-72 grid content-center font-primary-regular bg-sky-50 border-2 text-center border-dashed border-sky-500 rounded-lg cursor-pointer my-auto"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <input
        ref={fileInputRef}
        type="file"
        hidden
        accept="application/vnd.ms-excel"
        onChange={handleFileChange}
      />
      <div className="flex flex-col justify-center items-center ">
        {!file ? (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              viewBox="0 0 24 24"
              className="text-sky-500 hover:text-sky-700 my-2 fill-current"
            >
              <g
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              >
                <path d="M7 18a4.6 4.4 0 0 1 0-9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />
                <path d="m9 15l3-3l3 3m-3-3v9" />
              </g>
            </svg>
            <button onClick={() => fileInputRef.current.click()}>
              <span className="text-sky-500 text-sm md:text-base font-primary-semibold">
                Choose a File
              </span>
              <span className="text-sm md:text-base"> or Drag and drop the file</span>
            </button>
          </>
        ) : (
          <>
            <p className="font-gotham-normal">{file.name}</p>
            <div className="flex gap-2 my-2">
              <button
                className="bg-sky-500 text-white p-2 rounded font-gotham-normal"
                onClick={handleFileUpload}
              >
                Upload
              </button>
              <button
                className="border border-sky-500 text-sky-500 p-2 rounded font-gotham-normal"
                onClick={handleClear}
              >
                Clear
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FileUploadSection;