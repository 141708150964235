import axios from 'axios';
import React, { useState } from 'react'
import CONFIG from '../../utils/Config';
import { useClient } from '../../utils/context/ClientContext';

export default function OfferForm() {
    const clientContext = useClient();
    const[name, setName] = useState();
    const[message, setMessage] = useState();
    const[minimumAmount, setMinimumAmount] = useState();
    const[discount, setDiscount] = useState();    
    const[fromTime, setFromTime] = useState(Date.now());
    const[toTime, setToTime] = useState(Date.now());

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
          case "name":
            setName(value);
            break;
        case "message":
            setMessage(value);
            break;
        case "minAmount":
            setMinimumAmount(value);
            break;
        case "discount":
            setDiscount(value);
            break;
        case "fromTime":
            setFromTime(value);
            break;
        case "toTime":
            setToTime(value);
            break;
        }
    }

    const handleCreateOffer = (e) => {
        let offerData = {
            name:name,
            message:message,
            minimumAmount:minimumAmount,
            discount:discount,
            fromDate:fromTime,
            toDate:toTime
        }
        axios({
            url:CONFIG.URL+"client/createOffer",
            method:"POST",
            withCredentials: true,
            headers:{
                'X-TenantID': clientContext.tenantID
            },
            data: offerData
        }).then((res) => {
            alert("Offer Created Successfully!");
            setName("");
            setMessage("");
            setMinimumAmount("");
            setDiscount("");
            setFromTime(Date.now());
            setToTime(Date.now());
        }).catch((err) => {
            console.error(err);
        });
        e.preventDefault();        
    }
  return (
    <div>                    
      <div className="mt-5 md:mt-10 p-5 md:p-10 w-11/12 md:w-8/12 lg:w-4/12 max-w-7xl mx-auto shadow-md rounded-lg ">
        <div>
          <h3 className="text-xl font-primary-bold text-gray-800">
            Create New Offer
          </h3>
          <p className="text-gray-500 font-primary-regular text-sm">Here you can register a table, post registration you will get the QR code for the registered table.</p>
        </div>
        <hr className="my-5 border-gray-400 " />
        <form>
          <div className="mb-4">
            <label htmlFor="name" className="form-label">
              Name<span className='form-indicator'>*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={handleChange}
              placeholder='Offer Name'
              className={`form-input-text`}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="form-label">
              Message<span className='form-indicator'>*</span>
            </label>
            <input
              type="text"
              id="message"
              name="message"
              value={message}
              onChange={handleChange}
              placeholder='Offer Description'
              className={`form-input-text`}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="minAmount" className="form-label">
              Minimum amount<span className='form-indicator'>*</span>
            </label>
            <input
              type="number"
              id="minAmount"
              name="minAmount"
              value={minimumAmount}
              onChange={handleChange}
              placeholder='Minimum amount to avail offer'
              className={`form-input-text`}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="discount" className="form-label">
              Discount Percentage<span className='form-indicator'>*</span>
            </label>
            <input
              type="number"
              id="discount"
              name="discount"
              value={discount}
              onChange={handleChange}
              placeholder='Discount in % (eg: 10, 20, etc,.)'
              className={`form-input-text`}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="timeLine" className="form-label">
              Offer Timeline<span className='form-indicator'>*</span>
            </label>
            <div className='flex gap-4'>
                <input
                    type="date"
                    id="fromTime"
                    name="fromTime"
                    value={fromTime}
                    placeholder='Valid From'
                    onChange={handleChange}              
                    className={`form-input-text`}
                    />
                <input
                    type="date"
                    id="toTime"
                    name="toTime"
                    placeholder='Valid Till'
                    value={toTime}
                    onChange={handleChange}              
                    className={`form-input-text`}
                    />
            </div>
            
          </div>
          <div className="text-center ">
            <button className="form-btn " onClick={handleCreateOffer}>
              Create Offer
            </button>
          </div>
        </form>            
      </div>
    </div>
  )
}
