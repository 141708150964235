import React, { useEffect, useRef, useState } from 'react';
import DownArrowIcon from "../../../utils/icons/DownArrowIcon";
import { motion } from 'framer-motion'; // Import motion

export const Dropdown = ({ options, defaultValue, setValue, multiSelect = false, label, className = "", required = false }) => {
    const default_selected_label = "Select a value";
    const [dropdownSelected, setDropdownSelected] = useState(false);
    const [selectOpened, setSelectOpened] = useState(false);
    const wrapperRef = useRef(null); // Create a ref
    const [ selectedLabel, setSelectedLabel ] = useState(label);
    const [ selectedValues, setSelectedValues ] = useState([]);

    const handleSelectOpened = () => {
        setSelectOpened(!selectOpened);
    }

    useEffect(() => {
        const handleClickOutside = event => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setSelectOpened(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, );



    const handleSelect = ({ label, value }) => {
        setDropdownSelected(true);
        if (multiSelect) {
            const newSelectedValues = selectedValues.includes(value)
                ? selectedValues.filter(v => v !== value) // Remove value if already selected
                : [...selectedValues, value]; // Add value if not selected

            setSelectedValues(newSelectedValues);
            setValue(newSelectedValues);  // Update the parent component's value

            // Update the selected label (for display)
            setSelectedLabel(newSelectedValues.map(v => options.find(o => o.value === v).label).join(", ") || label)

        } else {
            setSelectedLabel(label);
            setSelectOpened(false);
            setValue(value);
        }
    };

    return (
        <>
            <div
                className={`p-3 border w-52 rounded-lg relative cursor-pointer ${selectOpened ? ("border-primary") : ("border-gray-300")} text-nowrap no-select ${className}`}
                ref={wrapperRef}   // Assign the ref to the dropdown div
            >
                {(dropdownSelected || defaultValue !== undefined) && (
                    <motion.div
                        className={"bg-white text-xs font-medium text-primary p-1 absolute -top-3.5 left-2.5"}
                        initial={{ y: -10, opacity: 0 }}  // Start above and hidden
                        animate={{ y: 0, opacity: 1 }}    // Slide down and fade in
                        transition={{ duration: 0.3, type: "spring", stiffness: 100, damping: 15 }} // Spring animation
                    >
                        {label}
                    </motion.div>
                )}
                <div className={"flex justify-between items-center w-full gap-3"} onClick={handleSelectOpened}>
                    <p className={`font-normal ${label === selectedLabel && defaultValue === undefined ? ("text-gray-400 "):( "text-gray-800 ")}`}>{defaultValue !== undefined ? options?.find(o => o.value === defaultValue).label : selectedLabel}</p>
                    <DownArrowIcon className={"w-5 h-5 text-gray-400"} />
                </div>
                {selectOpened === true && options && (
                    <motion.div // Use motion.div for the dropdown menu
                        className={"absolute top-12 p-2 border left-0 bg-white rounded-lg w-full h-36 overflow-y-auto scroll-m-2.5 z-20"}
                        initial={{opacity: 0, y: -10}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: -10}}
                        transition={{duration: 0.05, ease: "easeInOut"}}
                    >
                        {options && options.map(({value, label}, index) => (
                            <div
                                className={'flex items-center justify-start gap-2 text-nowrap hover:bg-blue-100 hover:text-primary px-2 rounded-lg'}
                                onClick={() => {
                                    handleSelect({label, value})
                                }}>
                                {multiSelect && (<input type="checkbox" checked={selectedValues.includes(value)}/>)}
                                <div
                                    key={index}
                                    className={`w-full p-2 font-light text-gray-800  cursor-pointer ${value === defaultValue && ("bg-blue-100 text-primary rounded-lg")}`}
                                >
                                    {label}
                                </div>
                            </div>

                        ))}
                    </motion.div>
                )}
            </div>
        </>
    );
};