import axios from 'axios';
import React, { createRef, useState } from 'react'
import CONFIG from '../../utils/Config';
import ProductTable from './TableComponent';
import { useNavigate } from 'react-router-dom';
import icons from '../../utils/Icons';

export default function TableForm() {
  const loadingRef = createRef();
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [capacity, setCapacity] = useState("");
  const [tables, setTables] = useState([]);
  const columns = [
    { field: "tableName", headerName: "Table Name" },
    { field: "capacity", headerName: "Capacity" }
  ]

  const handleRemoveTable = (name) => {
    
    let tempTables = tables.filter((e) => {
      return e.tableName != name
    });
    //console.log("handled");
    setTables(tempTables);
    console.log(tempTables);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "tableName":
        setName(value);
        break;
      case "capacity":
        setCapacity(value);
        break;
    }
  }
  const handleAddTable = (e) => {
    let tempTableData = {
      tableName: name,
      capacity: capacity
    }
    let table = tables;
    table.push(tempTableData);
    setTables(table);
    setName("");
    setCapacity("");
    e.preventDefault();
  }
  const handleSubmit = () => {
    let data = {
      tables: tables
    }    
    axios({
      url: CONFIG.URL + "client/registerTables",
      data: data,
      withCredentials:true,
      method:"POST",
      responseType: 'blob',
      headers: {
        'key': CONFIG.KEY,
        'token': CONFIG.TOKEN
      }

    }).then((res) => {
      const href = URL.createObjectURL(res.data);      
      const link = document.createElement('a');
      link.href = href;      
      link.setAttribute('download', "table_data.xls");      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setTables([]);                        
    }).catch((err) => {
      console.error(err);
    })
  }
  return (
    <div>                    
      <div className="mt-5 md:mt-10 p-5 md:p-10 w-11/12 md:w-8/12 lg:w-4/12 max-w-7xl mx-auto shadow-md rounded-lg ">
        <div>
          <h3 className="text-xl font-primary-bold text-gray-800">
            Register a Table
          </h3>
          <p className="text-gray-500 font-primary-regular text-sm">Here you can register a table, post registration you will get the QR code for the registered table.</p>
        </div>
        <hr className="my-5 border-gray-400 " />
        <form>
          <div className="mb-4">
            <label htmlFor="tableName" className="form-label">
              Name<span className='form-indicator'>*</span>
            </label>
            <input
              type="text"
              id="tableName"
              name="tableName"
              value={name}
              onChange={handleChange}
              placeholder='Table Name'
              className={`form-input-text`}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="productDescription" className="form-label">
              Capacity<span className='form-indicator'>*</span>
            </label>
            <input
              id="capacity"
              name="capacity"
              value={capacity}
              onChange={handleChange}
              placeholder='Capacity of table'
              className={`form-input-text`}
            />
          </div>
          <div className="text-center ">
            <button className="form-btn " onClick={handleAddTable}>
              Add Table
            </button>
          </div>
        </form>
        
        <div className="my-3 md:my-5">
           {tables && tables.length > 0 ?<><hr className='mt-10'/> <ProductTable listItems={tables} columns={columns} heading={"Added Tables"} handleRemoval={handleRemoveTable} handleSubmit = {handleSubmit} id={"tableName"} /></> : ''}</div>
      </div>
    </div>
  )
}
