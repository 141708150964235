import React from 'react'
import { motion } from 'framer-motion'
import Footer from '../elements/Footer'
import RoleList from '../elements/RoleList'


const RolesPage = () => {
    return (
        <div className="h-lvh grid grid-cols-1 content-between">
          <motion.div animate={{ y: -10 }} transition={{ duration: 0.5 }}>
            <RoleList />
          </motion.div>
          <Footer />
        </div>
      )
}

export default RolesPage