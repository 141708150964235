import React, { useRef, useEffect } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css"
import { motion } from "framer-motion";

const TableComponent = (props) => {

  let listItems = props.listItems;  
  let columns = props.columns;
  let heading = props.heading;
  let id = props.id;

  const containerRef = useRef(null);
  const [ps, setPs] = React.useState(null);

  useEffect(() => {
    if (containerRef.current) {      
      const ps = new PerfectScrollbar(containerRef.current);      
      setPs(ps);
    }    
    return () => {
      if (ps) {        
        ps.destroy();      
        setPs(null);
      }
    };
  }, [containerRef]);

  const handleItemRemoval = (e) => {
    props.handleRemoval(e);
  }
  const handleSubmit = () => {    
    props.handleSubmit();
  }
  useEffect(() => {
    if (ps) {
      ps.update();
    }
  });
  return (
    <motion.div
          animate={{ y: -10 }}
          transition={{ duration: 0.5 }}
          className="my-3 md:my-14"
        >    
      <StyledEngineProvider injectFirst>
        <h2 className="text-xl font-primary-semibold text-gray-900 my-2">{heading}</h2>
        <hr className="my-3 md:mb-5" />
        <Paper>
          <TableContainer ref={containerRef} style={{ height: "400px" }} className="relative rounded-3xl">
            <Table stickyHeader>
              <TableHead className="font-primary-medium">
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox color="primary" />
                  </TableCell>
                  {columns.map((column) => (
                    <TableCell key={column.field} className='table-head'>{column.headerName}</TableCell>
                  ))}
                  <TableCell className='table-head'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="font-primary-regular">
                {listItems.map((item) => (
                  <TableRow key={item.name}>
                    <TableCell padding="checkbox">
                      <Checkbox color="primary" />
                    </TableCell>
                    {columns.map((column) => (
                      <TableCell key={column.field} className="table-data">
                        {column.field === "category"
                          ? item.category.id
                          : item[column.field]}
                      </TableCell>
                    ))}
                    <TableCell className='table-data text-center'>
                    <button onClick={() => {console.log(item);handleItemRemoval(item[id])}}>
                      <svg
                        className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="red" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <div className = "mx-auto text-right">
          <button className="form-btn" onClick={handleSubmit}>
          Submit
        </button>
        </div>
        
      </StyledEngineProvider>
      
    </motion.div>
  );
};

export default TableComponent;