import { React, createContext, useContext, useEffect, useState } from 'react'
import { useWidgets } from '../context/WidgetContext';
import axios from 'axios';
import toast from 'react-hot-toast';
import CONFIG from '../Config';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const widgetContext = useWidgets();
  const [userData, setUserData] = useState(() => {
    const savedUserData = sessionStorage.getItem('userData');
    return savedUserData ? JSON.parse(savedUserData) : null;
  });
  const [authToken, setAuthToken] = useState(() => {
    const authToken = sessionStorage.getItem('authToken');
    return authToken ? authToken : null;
  });

  useEffect(() => {
    sessionStorage.setItem('userData', JSON.stringify(userData));
    sessionStorage.setItem('authToken', authToken);
  }, [userData, authToken]);

  const login = (userData, authToken) => {
    setUserData(userData);
    setAuthToken(authToken);
  }

  const logout = () => {
    setUserData(null);
    setAuthToken(null);
    axios({
      method: 'POST',
      url: CONFIG.URL + 'auth/logout',
      withCredentials: true,
    })
    widgetContext.resetWidgets();
  }
  return <AuthContext.Provider value={{ userData, authToken, login, logout }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}