import { createContext, useContext, useEffect, useState } from "react";

const WidgetContext = createContext();

export const WidgetContextProvider = ({ children }) => {
    const [widgets, setwidgets] = useState(() => {
        const widgets = sessionStorage.getItem('widgets');
        return widgets !== undefined ? JSON.parse(widgets) : null;
    });

    useEffect(() => {
        sessionStorage.setItem('widgets', JSON.stringify(widgets));
    }, [widgets]);

    const addWidget = (widget) => {
        let tempWidgets = [];
        if (widgets === null || widgets === undefined || widgets === "null") {
            tempWidgets.push(widget);
            setwidgets(tempWidgets);
        } else {
            if (widgets.filter((e) => {
                return e.widget.id === widget.widget.id
            }).length > 0) {
                return;
            }
            let existingWidgets = [...widgets];
            existingWidgets.push(widget);
            setwidgets(existingWidgets);
        }
    }

    const setWidgets = (widgets) => {
        setwidgets(widgets);
    }

    const resetWidgets = () => {
        setWidgets(null);
    }

    const getWidget = (widgetId) => {        
        if (widgets !== null && widgets !== "null" && widgets !== undefined && widgets.length > 0) {
            let widget = widgets.filter((e) => {
                return (e.widget.id === widgetId)
            });
            if(widget.length > 0){
                return widget[0];
            }            
        } else {
            return undefined;
        }
    }

    const reloadWidget = (widgetData) => {
        let existingWidgets = [...widgets];
        if (existingWidgets !== null && widgets !== undefined && widgets.length > 0) {
            existingWidgets = existingWidgets.filter((e) => {
                return (e.widget.id !== widgetData.widget.id)
            });
            existingWidgets.push(widgetData);       
            setwidgets(existingWidgets);
        }
    }

    return (<WidgetContext.Provider value={{ widgets, addWidget, setWidgets, getWidget, resetWidgets, reloadWidget }}>{children}</WidgetContext.Provider>)
}

export const useWidgets = () => {
    return useContext(WidgetContext);
}