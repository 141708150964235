import Chart from "react-apexcharts";
import { ClockIcon } from "@heroicons/react/24/solid";
import chartConfig from "../../../../utils/ChartConfig";
import axios from "axios";
import { useClient } from "../../../../utils/context/ClientContext";
import { useEffect, useState } from "react";
import CONFIG from "../../../../utils/Config";
import { useWidgets } from "../../../../utils/context/WidgetContext";
import ReloadIcon from "../../../../utils/icons/ReloadIcon";
import { Card, CardContent, CardHeader, Tooltip, Typography } from "@mui/material";
import { useAuth } from "../../../../utils/authentication/auth";


export function StatisticsChart({ id, type }) {

  const clientContext = useClient();
  const widgetContext = useWidgets();
  const [lastLoadedTime, setLastLoadTime] = useState();
  const [widgetData, setWidgetData] = useState(null);
  const [widgetLoaded, setWidgetLoaded] = useState(false);
  const [options, setOptions] = useState(null);
  const [series, setSeries] = useState(null);
  const [chartType, setChartType] = useState(chartConfig.TYPES.BAR.name);
  const auth = useAuth();

  useEffect(() => {
    let options;    
    let series;       
    if(widgetContext === undefined || widgetContext === null || widgetContext.getWidget(id) === undefined || widgetContext.getWidget(id) === null){
      axios({
        url: CONFIG.URL + "widgets/getWidgetData?widgetId=" + id,
        method: "GET",
        withCredentials: true,
        headers: {
          'X-TenantID': clientContext.tenantID
        }
      }).then((res) => {      
        setWidgetData(res.data);
        setWidgetLoaded(true);  
        let currentTime = new Date();
        let tempWidgetData = {
          widget : res.data,
          updateTime : currentTime
        }
        widgetContext.addWidget(tempWidgetData);        
        const colors = chartConfig.COLORS;     
        if (res.data !== null) {           
          let config = chartConfig.CONFIG;                      
          options = {
            ...config,
            colors: [],
            xaxis: {
              categories: res.data.xaxis,
            }
          }
          series = [
            {
              name: res.data.typeOfDataInAxis,
              data: res.data.yaxis,
            },
          ];                    
        }
        if (type === 3) {
          setChartType(chartConfig.TYPES.LINE.name);
          options.markers.size = 5;
          const randomInt = Math.floor(Math.random() * colors.length);
          options.colors = [colors[randomInt]];
        } else if (type === 4) {
          const randomInt = Math.floor(Math.random() * 4);
          options.colors = [colors[randomInt]];
          setChartType(chartConfig.TYPES.AREA.name);
          options.markers.size = 5;
        } 
        setOptions(options);
        setSeries(series);
      }).catch((err) => {
        console.error(err);
      })
    }else{
      let widgetData = widgetContext.getWidget(id);      
      setWidgetData(widgetData.widget);
      let time = new Date(widgetData.updateTime);
      const date = new Date();
      var diff = date.getTime() - time.getTime();
      var msec = diff;
      var hh = Math.floor(msec / 1000 / 60 / 60);
      msec -= hh * 1000 * 60 * 60;
      var mm = Math.floor(msec / 1000 / 60);
      msec -= mm * 1000 * 60;
      var ss = Math.floor(msec / 1000);
      msec -= ss * 1000;
      let config = chartConfig.CONFIG;   
      const colors = chartConfig.COLORS;        
      options = {
        ...config,
        colors: [],
        xaxis: {
          categories: widgetData.widget.xaxis,
        }
      }
      series = [
        {
          name: widgetData.widget.typeOfDataInAxis,
          data: widgetData.widget.yaxis,
        },
      ];  

      setOptions(options);
      setSeries(series);
      setLastLoadTime(hh + ":" + mm + ":" + ss);      
      setWidgetLoaded(true);
      if (type === 3) {
        setChartType(chartConfig.TYPES.LINE.name);
        options.markers.size = 5;
        const randomInt = Math.floor(Math.random() * colors.length);
        options.colors = [colors[randomInt]];
      } else if (type === 4) {
        const randomInt = Math.floor(Math.random() * 4);
        options.colors = [colors[randomInt]];
        setChartType(chartConfig.TYPES.AREA.name);
        options.markers.size = 5;
      }
    }        
  }, [widgetData]);

  const handleRefresh = () => {
    let options;   
    axios({
      url: CONFIG.URL + "widgets/getWidgetData?widgetId=" + id,
      method: "GET",
      headers: {
        'X-TenantID': clientContext.tenantID,
        Authorization: auth.authToken
      }
    }).then((res) => {        
        setWidgetData(res.data);
        setWidgetLoaded(true);  
        let currentTime = new Date();
        let tempWidgetData = {
          widget : res.data,
          updateTime : currentTime
        }             
        const colors = chartConfig.COLORS;     
        if (res.data !== null) {           
          let config = chartConfig.CONFIG;                      
          let options = {
            ...config,
            colors: [],
            xaxis: {
              categories: res.data.xaxis,
            }
          }
          let series = [
            {
              name: res.data.typeOfDataInAxis,
              data: res.data.yaxis,
            },
          ];   
          setOptions(options);
          setSeries(series);                 
        }
        if (type === 3) {
          setChartType(chartConfig.TYPES.LINE.name);
          options.markers.size = 5;
          const randomInt = Math.floor(Math.random() * colors.length);
          options.colors = [colors[randomInt]];
        } else if (type === 4) {
          const randomInt = Math.floor(Math.random() * 4);
          options.colors = [colors[randomInt]];
          setChartType(chartConfig.TYPES.AREA.name);
          options.markers.size = 5;
        } 
        widgetContext.reloadWidget(tempWidgetData);
        setLastLoadTime(0);
        setWidgetLoaded(true);
    }).catch((err) => {
      console.error(err);
    })
  }

  return (
    <>
      {widgetLoaded === true ? (
        <Card className="border rounded-lg border-blue-gray-100 shadow-sm" variant="outlined">          
          <CardHeader                              
            shadow={true}                      
          >
            <div className="font-primary-semibold py-2 mx-4">
              <Typography variant="h6" color="blue-gray">
                {widgetData.name}
              </Typography>
              <Typography variant="small" className="font-primary-light text-blue-gray-600">
              {widgetData.name}
            </Typography>
            </div>
            
          </CardHeader>
          <CardContent className="px-6 pt-5">
          <Chart
              options={options}
              series={series}
              type={chartType}
            />            
          </CardContent>
          <div className="border-t border-blue-gray-50 px-6 py-5 flex justify-between items-center">
            <div className="flex items-center font-normal text-blue-gray-600">
              <ClockIcon strokeWidth={2} className="h-4 w-4 text-blue-gray-400" />
              &nbsp;Last Updated {lastLoadedTime} min ago
            </div>
            <Tooltip title="Refresh" arrow placement="bottom">
              <div onClick={handleRefresh} className="cursor-pointer">
                <ReloadIcon className="w-6 h-6"/>
              </div>
            </Tooltip>            
          </div>
        </Card>
      ) : (<></>)}
    </>
  );
}

export default StatisticsChart;
