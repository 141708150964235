import "./index.css";
import ErrorPage from './components/pages/ErrorPage';
import RegistrationPage from './components/pages/RegisterationPage';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import Dashboard from './components/pages/DashboardPage';
import TableRegistrationPage from './components/pages/TableRegistrationPage';
import ContactUsPage from './components/pages/ContactUsPage';
import OfferCreationPage from "./components/pages/OfferCreationPage";
import ViewOffersPage from "./components/pages/ViewOffersPage";
import ViewProductsPage from "./components/pages/ViewProductsPage";
import LoginPage from "./components/pages/LoginPage";
import { AuthProvider } from "./utils/authentication/auth";
import RequireAuth from "./utils/authentication/RequireAuth";
import { ClientContextProvider } from "./utils/context/ClientContext";
import { WidgetContextProvider } from "./utils/context/WidgetContext";
import Navbar from "./components/elements/Navbar";
import AddUserPage from "./components/pages/AddUserPage";

import { CssBaseline, ThemeProvider } from '@mui/material';
import { ThemeProvider as Theme } from '@material-tailwind/react'
import { createTheme } from '@mui/material/styles';
import ViewUsersPage from "./components/pages/ViewUsersPage";
import RolesPage from "./components/pages/RolesPage";
import { useEffect, useLayoutEffect } from "react";
import ScrollToTop from "./ScrollToTop";
import {HomePage} from "./components/pages/HomePage";

// Define your theme with the custom font
const theme = createTheme({
  typography: {
    fontFamily: 'Outfit, sans-serif'
  },
  palette: {
    primary: {
      main: '#003470'
    }
  },
  shape: {
    borderRadius: '10px'
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          borderRadius: '20px'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          borderRadius: '10px'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          borderRadius: '10px'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        input: {
          borderRadius: '10px'
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        input: {
          borderRadius: '10px'
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          borderRadius: '10px'
        }
      }
    },
  }
});

function App() {
  return (
    <Theme >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ClientContextProvider>
          <WidgetContextProvider>
            <AuthProvider>
              <AnimatePresence>
                <Router>
                  <ScrollToTop />              
                  <div className="sticky top-0 z-40 font-primary">
                    <Navbar />
                  </div>                
                  <Routes>
                  <Route path="login" element={<LoginPage />}>
                  </Route>
                  <Route path="register" element={<RequireAuth><RegistrationPage /></RequireAuth>}>
                  </Route>
                  <Route path="products" element={<RequireAuth><ViewProductsPage /></RequireAuth>}>
                  </Route>
                  <Route path="error" element={<ErrorPage />}>
                  </Route>
                  <Route path="home" element={<RequireAuth><HomePage /></RequireAuth>}>
                  </Route>
                    <Route path="dashboard" element={<RequireAuth><Dashboard /></RequireAuth>}>
                  </Route>
                  <Route path="registerTable" element={<RequireAuth><TableRegistrationPage /></RequireAuth>}>
                  </Route>
                  <Route path="createOffer" element={<RequireAuth><OfferCreationPage /></RequireAuth>}>
                  </Route>
                  <Route path="viewOffers" element={<RequireAuth><ViewOffersPage /></RequireAuth>}>
                  </Route>
                  <Route path="contactUs" element={<RequireAuth><ContactUsPage /></RequireAuth>}>
                  </Route>
                    <Route path="iam/addUser" element={<RequireAuth><AddUserPage /></RequireAuth>} />
                    <Route path="iam/manageUser" element={<RequireAuth><ViewUsersPage /></RequireAuth>} />
                    <Route path="iam/roles" element={<RequireAuth><RolesPage /></RequireAuth>} />
                    <Route path="*" element={<Navigate to="/home" />} />
                  </Routes>
                </Router>
              </AnimatePresence>
            </AuthProvider>
          </WidgetContextProvider>
        </ClientContextProvider>
      </ThemeProvider>
    </Theme>
  );
}

export default App;
