import React from "react";
import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import CleFactura from "../../assets/images/CleFactura.png";
import Emoji from '../../assets/images/smiling_face_with_smiling_eyes_3d.png'
import FONT_REGULAR from  "../../assets/fonts/KumbhSans-Regular.ttf";
import FONT_MEDIUM from  "../../assets/fonts/KumbhSans-Medium.ttf";
import FONT_SEMIBOLD from  "../../assets/fonts/KumbhSans-SemiBold.ttf";
import FONT_BOLD from  "../../assets/fonts/KumbhSans-Bold.ttf";
import FONT_BLACK from  "../../assets/fonts/KumbhSans-Black.ttf";
import { useClient } from "../../utils/context/ClientContext";


// Register Khumbh Sans font family with different styles and weights
Font.register({ family: 'Kumbh Sans', fonts: [
  { src: FONT_REGULAR,  fontWeight: 'normal' }, // font-style: normal, font-weight: normal
  { src: FONT_MEDIUM, fontWeight: 'medium' },
  { src: FONT_SEMIBOLD, fontWeight: 'semibold' },
  { src: FONT_BOLD, fontWeight: 'heavy' },
  { src: FONT_BLACK, fontWeight: 'ultrabold' }
 ]});

// Create styles
const styles = StyleSheet.create({
  container: {
    maxWidth: 900,
    margin: "20px auto",
    backgroundColor: "#fff",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
    fontFamily: "Kumbh Sans",
  },
  header: {
    padding: "10px 40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
    fontWeight: 900,
  },
  headerImage: {
    width: "40px",
    height: "40px",
  },
  headerText: {
    fontSize: 20,
    fontWeight: "semibold",
  },
  headerDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 5,
  },
  invoiceDate: {
    borderRight: "2px solid #334155",
    padding: "0 10px",
  },
  invoiceDetails: {
    height: "auto",
    backgroundColor: "#f1f5f9",
    margin: "10px 0",
    padding: "20px 40px",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  adminInfo: {
    flexGrow: 1,
  },
  customerInfo: {
    flexGrow: 1,
  },
  table: {
    width: '90%',
    margin:'10px auto'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent:'space-between',
    padding: "8px",
    
  },
  tableHeader:{
    backgroundColor:"#334155",
    color:"#fff",
    fontSize:'10px',
    fontWeight:"semibold",
     textAlign:'center',
  },
  tableBody:{
    fontSize:'10px',
    borderBottom:'1px solid #EEE'
  },
  tableAmount:{
    fontSize:'9px',
    padding:'4px'
  },
  paymentDetails:{
    height: "auto",
    margin: "10px 0",
    padding: "20px 40px",
    borderBottom:'1px solid #EEE'
  }
});

const PdfDocument = ({orderData, clientData}) => {
  const clientContext = useClient();
  return (
  <Document>
    <Page style={styles.container}>
      <View style={styles.header}>
        <View style={styles.headerTitle}>
          <Image src={CleFactura} style={styles.headerImage} />
          <Text style={styles.headerText}>Cle Factura</Text>
        </View>
        <View style={styles.headerDetails}>
          <View style={styles.invoiceDate}>
            <Text style={{ fontSize: 10, lineHeight: 1.5,fontWeight:"semibold", marginLeft:"auto" }}>
              Date
            </Text>
            <Text style={{ fontSize: 10, lineHeight: 1.5 }}>
              {orderData.orderTime}
            </Text>
          </View>
          <View>
            <Text style={{  fontSize: 10, lineHeight: 1.5,fontWeight:"semibold" }}>
              Invoice
            </Text>
            <Text style={{ fontSize: 10, lineHeight: 1.5, fontWeight:'bold'}}>
              #{orderData.orderId}
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.invoiceDetails}>
        <View style={styles.adminInfo}>          
          <Text style={{ fontWeight:'semibold',fontSize: 12,lineHeight: 1.5 }}>
            {clientData !== undefined && clientData.name}
          </Text>
          {clientData !== undefined && clientData.addressLine1 !== '' && (<Text style={{ fontSize: 10, lineHeight: 1.5 }}>{clientData.addressLine1} </Text>)}
          {clientData !== undefined && clientData.addressLine2 !== '' && (<Text style={{ fontSize: 10, lineHeight: 1.5 }}>{clientData.addressLine2} </Text>)}
          {clientData !== undefined && clientData.landmark !== '' && (<Text style={{ fontSize: 10, lineHeight: 1.5 }}>{clientData.landmark} </Text>)}
          {clientData !== undefined && clientData.district !== '' && (<Text style={{ fontSize: 10, lineHeight: 1.5 }}>{clientData.district} </Text>)}
          {clientData !== undefined && clientData.state !== '' && (<Text style={{ fontSize: 10, lineHeight: 1.5 }}>{clientData.state} - {clientData.pincode}</Text>)}
          {clientData !== undefined && clientData.contactNumber !== '' && (<Text style={{ fontSize: 10, lineHeight: 1.5 }}>PH: {clientData.contactNumber}</Text>)}
        </View>
        <View style={styles.customerInfo}>
          <Text style={{fontSize: 10,lineHeight: 1.5, fontWeight:"semibold" }}>
            Customer Details
          </Text>
         <Text style={{ fontWeight:'semibold',fontSize: 12,lineHeight: 1.5 }}>
            {sessionStorage.getItem("fullName")}
          </Text>
          <Text style={{ fontSize: 10, lineHeight: 1.5 }}>Type: Dine-in</Text>
          <Text style={{ fontSize: 10, lineHeight: 1.5 }}>Table: 1</Text>
        </View>
      </View>
      {/* Custom */}
      <View style={styles.table}>
      <View style={[styles.row, styles.tableHeader]}>
        <Text  style={{flexBasis:"30%"}}>Product Name</Text>
        <Text  style={{flexBasis:"20%"}}>Quantity</Text>
        <Text  style={{flexBasis:"20%"}}>Rate</Text>
        <Text style={{flexBasis:"20%"}}>Price (in Rupees)</Text>
      </View>
      {
        orderData.bill !== undefined && orderData.bill.billDetails !== undefined && orderData.bill.billDetails !== null && (
          orderData.bill.billDetails.map((productData, count) => {
            return (
            <View  style={[styles.row,styles.tableBody]} key={count}>
              <Text style={{flexBasis:"30%"}}>{productData.product.name}</Text>
              <Text style={{flexBasis:"20%",textAlign:'center'}}>{productData.quantity}</Text>
              <Text style={{flexBasis:"20%",textAlign:'center'}}>{productData.rate}</Text>
              <Text style={{flexBasis:"20%",textAlign:'center'}}>{productData.amount}</Text>
            </View>);
          })
        )

      }        
        <View  style={[styles.row,styles.tableAmount]} >
          <Text style={{flexBasis:"30%"}}></Text>
          <Text style={{flexBasis:"20%"}}></Text>
          <Text style={{flexBasis:"20%",textAlign:'right'}}>CGST(2.5%)</Text>
          <Text style={{flexBasis:"20%",textAlign:'center'}}>9</Text>
        </View>
        <View  style={[styles.row,styles.tableAmount]} >
          <Text style={{flexBasis:"30%"}}></Text>
          <Text style={{flexBasis:"20%"}}></Text>
          <Text style={{flexBasis:"20%",textAlign:'right'}}>SGST(2.5%)</Text>
          <Text style={{flexBasis:"20%",textAlign:'center'}}>9</Text>
        </View>
        <View  style={[styles.row,styles.tableAmount]} >
          <Text style={{flexBasis:"30%"}}></Text>
          <Text style={{flexBasis:"20%"}}></Text>
          <Text style={{flexBasis:"20%",textAlign:'right'}}>Convienience Fee (5%)</Text>
          <Text style={{flexBasis:"20%",textAlign:'center'}}>18</Text>
        </View>
        <View  style={[styles.row,styles.tableBody,{borderTop:'1px solid #eee'}]} >
          <Text style={{flexBasis:"30%"}}></Text>
          <Text style={{flexBasis:"20%"}}></Text>
          <Text style={{flexBasis:"20%",textAlign:'right', fontWeight:'semibold'}}>Total</Text>
          <Text style={{flexBasis:"20%",textAlign:'center', fontWeight:'semibold'}}>{orderData !== undefined && (orderData.amount)}</Text>
        </View>
    </View>
      <View style={styles.paymentDetails}>
        <View style={styles.adminInfo}>
          
          <Text style={{ fontWeight:'semibold',fontSize: 12,lineHeight: 2 }}>
            Payment Details:
          </Text>
          <View style={{display:'flex',flexDirection:'row'}}><Text style={{fontWeight:"semibold",fontSize:10,marginRight:'3px'}}>Order Id:</Text> <Text style={{ fontSize: 10, lineHeight: 1.7}}>{orderData.orderId}</Text></View>
          <View style={{display:'flex',flexDirection:'row'}}><Text style={{fontWeight:"semibold",fontSize:10,marginRight:'3px'}}>Transaction Id:</Text> <Text style={{ fontSize: 10, lineHeight: 1.7}}> 254A54DF2XFSDFE564</Text></View>
          <View style={{display:'flex',flexDirection:'row'}}><Text style={{fontWeight:"semibold",fontSize:10,marginRight:'3px'}}>Payment Mode: </Text> <Text style={{ fontSize: 10, lineHeight: 1.7}}>Gpay</Text></View>
        </View>
      </View>
      <View style={{display:'flex', flexDirection:"row", justifyContent:"center", alignItems:"center"}}><Image src={Emoji} style={{width:"16px", height:"16px"}}/> 
        <Text style={{fontWeight:"normal",fontSize:10,textAlign:'center', margin:'3px'}}>Thanks for Shopping with us!</Text></View>

      <View style={{display:'flex',gap:"7px",width:"95%", margin:"10px auto", alignItems:"flex-end"}}>
        <View><Text style={{color:'#4b5563', fontSize:'9px', fontWeight:"medium",marginBottom:"3px"}}>Powered By:</Text>
        <View style={{display:"flex",flexDirection:"row",alignItems:"center", gap:"3px"}}> <Image src={CleFactura} style={{width:'20px', height:'20px'}} />
          <Text style={{fontWeight:"semibold",fontSize:'10px'}}>Clè-Factura</Text></View>
       </View></View>
    </Page>
  </Document>
)};

export default PdfDocument;

