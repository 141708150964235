import React, { useEffect } from 'react'
import KebabIcon from '../../utils/icons/KebabIcon';

export default function OfferItem() {
    

    return (
        
                <div className='relative bg-white shadow-xl border border-gray-200 rounded-2xl w-full md:w-60 h-80'>
                    <button className='absolute top-5 right-3 '>
                        <KebabIcon className="text-gray-800 fill-current w-5" />
                    </button>
                    <div className='flex justify-center items-center h-3/6 border-b-2 border-dashed border-sky-700'>
                        <div className='text-5xl font-primary-black'>
                            <h2 className="text-mask">75%</h2>
                            <h2 className="text-mask">OFF</h2>
                        </div>
                    </div>
                    <div className='p-2.5 bg-sky-100 h-3/6 text-center text-gray-800 rounded-b-2xl  border-b border-gray-200'>
                        <h2 className='text-lg  font-bold'>SUMMER DHAMAKA</h2>
                        <p className='text-sm'>Offers on orders above ₹500</p>
                        <div className='flex items-center rounded-md gap-2  mx-auto px-5 py-2.5 bg-white my-2.5'>
                            <p className='text-sm '>Coupon Code</p>
                            <p className='text-sm font-primary-semibold text-gray-800 text-center'>CF01SR01</p>
                        </div>
                        <p className='font-semibold text-sm'>Offer valid till <span>20 April 2024</span></p>
                    </div>
                </div>
          
    );
}
