import React,{useEffect, useState} from 'react'

export default function Header() {
    const [currentDate, setCurrentDate] = useState('');
    const [currentTime, setCurrentTime] = useState('');
    const [amPm, setAmPm] = useState('');

    useEffect(() => {
        const updateTime = () => {
            const now = new Date();
            setCurrentDate(now.toLocaleDateString('en-US', {
                day: '2-digit', month: '2-digit', year: 'numeric'
            }));
            setCurrentTime(now.toLocaleTimeString('en-US', {
                hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true
            }).slice(0, -3));
            setAmPm(now.toLocaleTimeString('en-US', { hour12: true }).slice(-2));
        };

        updateTime();
        const intervalId = setInterval(updateTime, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="w-full h-6 bg-primary flex justify-between items-center font-light">
            <div className="w-11/12 mx-auto flex justify-end items-center">
                <div className="flex items-center text-gray-200 text-sm ">
                    <div className={"mr-1"}>{currentDate} {"|"}</div>
                    <div className={"w-14"}>{currentTime}</div>
                    <div className={"ml-0.5"}>{amPm}</div>
                </div>
            </div>
        </div>
    );
}
