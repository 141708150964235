import React from 'react'
import ProductForm from '../elements/ProductForm'
import { motion } from 'framer-motion'
import Footer from '../elements/Footer'

function RegistrationPage() {
  return (
    <div>      
      <motion.div animate={{ y: -10 }} transition={{ duration: 0.5 }}>
        <ProductForm />
      </motion.div>
      <Footer />
    </div>
  );
}
export default RegistrationPage