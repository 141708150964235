import {RecentOrderList} from "./RecentOrderList";
import DashboardIcon from "../../utils/icons/DashboardIcon";
import React, {useEffect, useState} from "react";
import RedirectIcon from "../../utils/icons/RedirectIcon";
import {APPLICATION_ENDPOINTS, AUTH_TYPE, fetchData, METHOD_TYPE} from "../../utils/RequestUtils";
import {useClient} from "../../utils/context/ClientContext";

export const RecentOrdersSection = () => {
    const [recentOrderSectionData, setRecentOrderSectionData] = useState();
    const { tenantID } = useClient();
    useEffect(() => {
        fetchData(APPLICATION_ENDPOINTS.GET_RECENT_ORDERS_SECTION, undefined, METHOD_TYPE.GET, AUTH_TYPE.AUTHENTICATED, tenantID, 'json')
            .then((response) => {
                setRecentOrderSectionData(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
    },[])
    return (
        <>
            <div className={"w-full md:w-5/12 lg:w-3/12 flex flex-col gap-4 mx-auto border border-gray-200 rounded-lg shadow-lg p-4"}>
                <div className={"flex justify-between w-full h-fit"}>
                    <div className={"flex justify-between items-center gap-2 w-full cursor-pointer"}>
                        <div className={"flex items-center gap-2"}>
                            <DashboardIcon className={"w-6 h-6 text-primary"}/>
                            <p>{recentOrderSectionData?.totalOrders || 0} <span>Orders</span></p>
                        </div>
                        <div className={"flex items-center gap-2"}>
                            <p className={"text-sm text-primary"}>View All</p>
                            <RedirectIcon className={"w-3 h-3 text-primary fill-current"}/>
                        </div>
                    </div>
                </div>
                <div className={"flex justify-between items-start gap-2 *:py-5 *:text-center mb-3"}>
                    <div className={"w-6/12 bg-blue-50  rounded-lg"}>
                        <h4 className={"font-semibold text-2xl text-primary"}>{recentOrderSectionData?.pendingOrders || 0}</h4>
                        <p className={"text-xs text-primary"}>Pending Orders</p>
                    </div>
                    <div className={"w-6/12 bg-blue-50 rounded-lg"}>
                        <h4 className={"font-semibold text-2xl text-primary"}>₹ {recentOrderSectionData?.todaySales || 0}</h4>
                        <p className={"text-xs text-primary"}>Total Sales</p>
                    </div>
                </div>
                <RecentOrderList/>
            </div>
        </>
    )
}
