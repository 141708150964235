
import { createContext, useContext, useEffect, useState } from "react";

const ClientContext = createContext();

export const ClientContextProvider = ({children}) => {        
    const [tenantID, setTenantID] = useState(() => {
        const tenantID = sessionStorage.getItem('tenantID');
        if(tenantID !== undefined && tenantID !== null){
            return tenantID;
        }else{
            return null;
        }
    });
    const [tenantData, setTenantData] = useState(() => {
        const savedUserData = sessionStorage.getItem('tenantData');
        return savedUserData ? JSON.parse(savedUserData) : null;
    });

    useEffect(() => {            
        sessionStorage.setItem('tenantID', tenantID);
        sessionStorage.setItem('tenantData', JSON.stringify(tenantData));
    }, [tenantID, tenantData]);

    const setTenantId  = (tenantID) => {        
        setTenantID(tenantID);
    }
    const setTenant  = (data) => {
        setTenantData(data);
    }

    return <ClientContext.Provider value={{tenantID, tenantData, setTenantId, setTenant}}>{children}</ClientContext.Provider>
}

export const useClient = () => {
    return useContext(ClientContext);
}
