import React from 'react';

const Button = ({ label, onClick, variant = 'filled', className, type }) => {
    let buttonClassName = "text-sm font-normal py-1.5 px-3 rounded-lg text-nowrap w-fit";

    switch (variant) {
        case 'outline':
            buttonClassName += " border border-primary text-primary hover:bg-blue-100";
            break;
        case 'filled':
            buttonClassName += " bg-primary text-white hover:bg-blue-200";
            break;
        case 'text':
            buttonClassName += " text-primary hover:bg-blue-100";
            break;
        default:
            buttonClassName += " bg-primary text-white hover:bg-blue-200";
    }

    return (
        <div>
            <button className={className+ " " +buttonClassName} onClick={onClick} type={type}>
                {label}
            </button>
        </div>
    );
};

export default Button;