import React from 'react'
import Footer from '../elements/Footer'
import { motion } from "framer-motion";
import OfferForm from '../elements/OfferForm';

function OfferCreationPage() {
  return (
    <div className="h-lvh grid grid-cols-1 content-between">
        <motion.div animate={{ y: -10 }} transition={{ duration: 0.5 }}>
          <OfferForm/>
        </motion.div>
        <Footer />
      </div>
  )
}

export default OfferCreationPage