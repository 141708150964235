import React from 'react'

export  default function SuccessIcon({width, height, className}) {
  return (
      <svg width={width} height={height} className={className} viewBox="0 0 1024 1024"
           xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor"
                d="M512 64a448 448 0 1 1 0 896a448 448 0 0 1 0-896m-55.808 536.384l-99.52-99.584a38.4 38.4 0 1 0-54.336 54.336l126.72 126.72a38.27 38.27 0 0 0 54.336 0l262.4-262.464a38.4 38.4 0 1 0-54.272-54.336z"/>
      </svg>
  )
}
