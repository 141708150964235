import React, { useEffect, useRef, useState } from 'react'
import LOGO from '../../assets/images/CleFactura.png'
import axios from 'axios';
import CONFIG from '../../utils/Config';
import ReCAPTCHA from 'react-google-recaptcha';
import toast, { Toaster } from 'react-hot-toast';
import OTPInput from 'react-otp-input';
import Timer from '../elements/Timer';
import { useAuth } from '../../utils/authentication/auth';
import { useNavigate } from 'react-router-dom';
import { useClient } from '../../utils/context/ClientContext';
import Footer from '../elements/Footer';
import { Checkbox, FormControlLabel, FormGroup} from '@mui/material';
import { decryptData } from '../../utils/EncryptionUtil'
import {APPLICATION_ENDPOINTS, AUTH_TYPE, fetchData, METHOD_TYPE} from "../../utils/RequestUtils";

function LoginPage() {
  const clientContext = useClient();
  const auth = useAuth();  
  const [email, setEmail] = useState("");
  const emailInputField = useRef();
  const [otp, setOtp] = useState(0);
  const [time, setTime] = useState(60);
  const navigate = useNavigate();
  const [captchaValidated, setCaptchaValidated] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({
    captcha: false,
    mobile: false
  });

  useEffect(() => {    
    if(auth.userData !== null){
      navigate("/home");
    }
    fetchData(APPLICATION_ENDPOINTS.GET_AUTH_DETAILS, undefined, METHOD_TYPE.POST, AUTH_TYPE.AUTHENTICATED, undefined)
        .then((response) => {   
          let userData = JSON.parse(decryptData(response.data.userData));
          let authToken = response.data.authToken;
          auth.login(userData, authToken);
          clientContext.setTenantId(userData?.tenantId)
          auth.login(userData, authToken);
          navigate("/home");
        }).catch(error => {

        })
  },[])

  const [otpGenerated, setOtpGenerated] = useState(false);
  const handleLogin = (e) => {
    let data = {
      mailId: email,
      otp: otp,
      rememberMe: rememberMe
    }
    axios({
      withCredentials: true,
      url: CONFIG.URL + "auth/validateOTP",
      data: data,
      method: 'POST'
    }).then((res) => {
      if (res.data.status === "Success") {
        let userData = JSON.parse(decryptData(res.data.userData));
        let tenantData = JSON.parse(decryptData(res.data.tenantData));
        let authToken = res.data.authToken;
        auth.login(userData, authToken);
        clientContext.setTenant(tenantData);
        console.log(userData);
        navigate("/home");
      }
    }).catch((err) => {
      console.error(err);
    })
  }
  const handleGeneratingOTP = (e) => { 
    if(email.length < 1){
      emailInputField.current.classList = 'form-input-text-error'
      toast.error('Please enter a valid email address');
      return;
    }else{
      emailInputField.current.classList = 'form-input-text';
    }
    setLoading(true);
    if (captchaValidated != null) {
      setErrors({
        ...errors,
        captcha: false
      })
      let data = {
        mailId: email
      }      
      axios({
        url: CONFIG.URL + "auth/generateOTP",
        data: data,
        method: 'POST'
      }).then((res) => {                
        if (res.data.Status === "Success") {          
          clientContext.setTenantId(res.data.tenantId)
        }
      }).catch((err) => {     
        console.error(err);
      });      
    } else {
      setErrors({
        ...errors,
        captcha: true
      })
    }
    setLoading(false);
    if(otpGenerated === true){
      toast.success('OTP has been resent to your mail');      
    }else{
      toast.success('OTP sent successfully to your mail');      
    }
    
    setOtpGenerated(true);
  }

  return (
    <> <div className="bg-sky-50 h-screen flex items-center font-primary">
      <Toaster toastOptions={{ duration: 2000 }} />
      <div className="block md:flex items-center justify-evenly bg-gray-50 rounded-3xl w-11/12 sm:w-10/12 md:w-9/12 lg:w-8/12 h-auto mx-auto py-10 md:py-20 px-5 *:px-3 shadow-2xl">
        <div className=" flex w-full gap-2 md:gap-4 md:w-6/12  justify-start items-center ">
          <img src={LOGO} alt="" className="w-14 md:w-24 h-14 md:h-24 " />
          <div className="flex flex-col gap-0.5 justify-center  ">
            <h1 className="font-primary-black text-2xl md:text-3xl">
              Cle Factura
            </h1>
            <p className="font-primary-light text-xs md:text-lg">
              A Companion to manage your business
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-1 md:gap-3 w-full  md:w-5/12 mt-10 md:mt-0">
          {errors.mobile === true ? (
            <p className="text-red-500 font-primary-light">
              Kindly check your Mobile Number
            </p>
          ) : (
            <></>
          )}
          {errors.captcha === true ? (
            <p className="text-red-500 font-primary-light">
              Kindly validate captcha
            </p>
          ) : (
            <></>
          )}

          <h1 className="text-xl md:text-2xl font-primary-semibold">Login</h1>
          <div className="flex flex-col gap-2">
            {otpGenerated === false ?
              <input
                type="email"
                ref={emailInputField}
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email@example.com"
                autoComplete="off"
                className="form-input-text"
              /> : <></>}
            {otpGenerated === true ? (
              <div className="text-black">
                <div className='text-base font-primary-regular pb-3 text-gray-600'>
                  <p>
                    OTP has been sent to {email}
                  </p>
                </div>
                <OTPInput
                  value={otp}
                  inputType="text"
                  onChange={setOtp}
                  placeholder="0"
                  numInputs={4}
                  skipDefaultStyles="true"
                  inputStyle="border rounded-md p-1  w-9 md:w-10 h-9 md:h-10 mx-1 md:mx-1.5 text-center "
                  renderInput={(props) => <input {...props} />}
                />
                <div className='flex text-sm justify-between items-center mt-3 w-full'>
                  <div className='flex gap-1'>
                    <p className='font-primary-regular '>Didn't you receive the OTP?</p>
                    <p className={` font-primary-medium ${time > 0 ? 'text-gray-400' : 'text-sky-500 cursor-pointer'}`} onClick={handleGeneratingOTP}>Resend OTP</p>
                  </div>
                  {time > 0 ? (
                    <div className='right-0'>
                      <Timer time={time} setTime={setTime} />
                    </div>
                  ) : (<></>)
                  }

                </div>
              </div>
            ) : (
              <></>
            )}
            {otpGenerated === false ?
              <ReCAPTCHA
                sitekey="6LeQCK0pAAAAAC7R5JrhLx-oI8vnUsFBXUgKX59Y"
                onChange={(val) => {
                  setCaptchaValidated(val);
                }}
                className="mb-2 scale-75 xl:scale-100 origin-left "
              /> : <></>
            }
          </div>
          {otpGenerated === false ? (
            <div>
              <button
                className="bg-primary-blue text-base font-primary-medium text-white px-4 py-1 rounded-lg hover:bg-primary-dark-blue flex gap-2 items-center"
                onClick={handleGeneratingOTP}
              > 
                {!loading ? <></>:<svg                  
                  class=" w-6 h-6 text-gray-200 animate-spin dark:text-gray-200 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg> }
                Get OTP
              </button>
            </div>
          ) : (
            <div>
              <button
                className="bg-primary-blue text-base font-primary-medium text-white px-4 py-1 rounded-lg hover:bg-primary-dark-blue"
                onClick={handleLogin}
              >
                Login
              </button>
            </div>
          )}
          <FormGroup>
            <FormControlLabel control={<Checkbox onChange={(e) => setRememberMe(e.target.checked)} size='small'/>} label="Remember me" />
          </FormGroup>
          <div className="mb-5 *:text-sm *:md:text-base">
            <hr />
            <p className="text-blue-gray-600 my-2">
              Forgot your password ?{" "}
              <a href="/" className="text-blue-500 hover:text-blue-700">
                Click here
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>

  );
}

export default LoginPage