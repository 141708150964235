import React, { useEffect, useLayoutEffect, useState } from "react";
import { motion } from "framer-motion";
import Product from "../elements/Product";
import Footer from "../elements/Footer";
import axios from "axios";
import CONFIG from "../../utils/Config";
import ProductEditForm from "../elements/ProductEditForm";
import toast, { Toaster } from "react-hot-toast";
import Filter from "../elements/Filters";
import { useClient } from "../../utils/context/ClientContext";
import { useAuth } from "../../utils/authentication/auth";
import {useNavigate} from "react-router-dom";
import SearchBox from "../elements/SearchBox";
import MenuItemVertical from "../elements/MenuItemVertical";

const ViewProductsPage = () => {
  const clientContext = useClient();
  const auth = useAuth();
  const [productEditVisibility, setProductEditVisibility] = useState(false);
  const [products, setProducts] = useState(null);
  const [filter, setAppliedFilter] = useState(null);
  const [currentProduct, setCurrentProduct] = useState(null);
  useLayoutEffect(() => {  
    getProducts(0);    
  },[]);
  const navigate = useNavigate()
  const getProducts = (type, id) =>  {    
    let tempFilter;
    if (!filter) {
      tempFilter = {};
    } else {
      tempFilter = filter;
    }
    let ids = [];    
    if(id !== undefined){
      try{
        id.map((data) => {
          return (ids.push(data));
        });
      }catch (error){
        ids.push(id);
      }
    }    
    switch (type) {
      case 1:
        tempFilter.category = ids;
        break;
      case 2:
        tempFilter.subCategory = ids;
        break;
      case 3:
        tempFilter.timeId = ids;
        break;
      default:
        break;
    }
    setAppliedFilter(tempFilter);
    let data = {
      filter: tempFilter,
    };
    axios({
      url: CONFIG.URL + "public/product/getProducts",
      method: "POST",      
      headers: {
        'X-TenantID': clientContext.tenantID
      },
      data:data
    })
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => {
        console.error(err.message);
      });
  }
const handleClick = (id) => {        
  axios({
    url:CONFIG.URL+"product/get/"+id,
    method:'GET',
    withCredentials: true,
    headers:{
      'X-TenantID': clientContext.tenantID
    }
  }).then((res) => {
    setCurrentProduct(res.data);
    setProductEditVisibility(productEditVisibility ? false : true);  
  }).catch((err) => {
    console.error(err);
  })
};
const handleDelete = (id) => {
  let deleteConfirmation = window.confirm("Are you sure you want to delete the Product ? This cant be reverted.");
  if(deleteConfirmation){
    axios({
      url:CONFIG.URL+'product/delete/'+id,
      method:'POST',
      headers:{
        'key':CONFIG.KEY,
        'token':CONFIG.TOKEN
      }
    }).then((res) => {
      if(res.data.Status === 'Success'){
        toast.success("Product removed successfully", {duration:3000});
        getProducts(1);
        
      }else{
        toast.error("Product removal Failed Please try again after sometime", {duration:3000});
      }
    }).catch((err) => {
      console.error(err);
    })
  }    
}
const handleAddProducts = () =>{
  navigate('/register')
}

  const [ searchString, setSearchString ] = useState('');

  const handleSearch = (searchString) => {
    setSearchString(searchString);
  }
  return (
    <div className="h-lvh grid grid-cols-1 content-between">
      <Toaster />
      {productEditVisibility && (
        <ProductEditForm
          visibility={productEditVisibility}
          handleVisibility={handleClick}
          productData={currentProduct}
        />
      )}
      <motion.div animate={{ y: -10 }} transition={{ duration: 0.5 }}>
        <div className="mx-auto flex flex-col justify-start items-center gap-4 w-11/12 md:w-10/12 mt-10 *:w-full">
          <div className={"w-10/12 mx-auto flex justify-between items-center border-b border-gray-200 py-2 mt-10"}>
            <h3 className="text-xl font-primary-semibold">Products</h3>
            <button
                className={"text-sm border border-primary text-primary font-normal py-1 px-3 rounded-lg hover:bg-blue-100"}
                onClick={handleAddProducts}>+
              Add Product(s)
            </button>
          </div>
          <div className="h-fit items-start bg-white z-20 py-3 flex flex-col gap-4">
            <SearchBox handleSearch={handleSearch}/>
            <Filter handleProductFilter={getProducts}/>
          </div>
          <div
              className="overflow-y-auto h-[750px] scrollbar-hide w-full p-2 grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 mx-auto border border-gray-200 rounded-lg  gap-2 md:gap-4"
          >
            {products && products.length > 0 ? (
                products.map((data) => (
                    <MenuItemVertical
                        key={data.item.id}
                        product={data.item}
                        handleClick={handleClick}
                        handleDelete={handleDelete}
                    />
                ))
            ) : (
                <>No Data Found</>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ViewProductsPage;


