import axios from "axios";
import CONFIG from "./Config";

export const METHOD_TYPE = {
    POST: 'POST',
    GET: 'GET',
}

export const AUTH_TYPE = {
    AUTHENTICATED : 'authenticated',
    UNAUTHENTICATED : 'unauthenticated'
}

export const APPLICATION_ENDPOINTS = {
    GET_WIDGETS: 'widgets/getWidgets',
    GET_WIDGET_DATA: 'widgets/getWidgetData',
    GET_AUTH_DETAILS: 'auth/login',
    GET_ACTIVE_ORDERS: 'client/getActiveOrders',
    GET_RECENT_ORDERS_SECTION: 'client/getRecentOrdersData',
    DOWNLOAD_RECEIPT: 'client/downloadReceipt',
    SAVE_PRODUCT: 'product/save',
}

export const fetchData = (url, parameters, methodType, authType, tenantId, responseType = 'json', data) => {
    const config = {
        withCredentials: authType === AUTH_TYPE.AUTHENTICATED,
        url: CONFIG.URL + url,
        responseType: responseType,
        method: METHOD_TYPE[methodType],
        headers: {
            'X-TenantID' : tenantId
        }
      }
      if(parameters){
        config.params = parameters;
      }
      if(data){
          config.data = data;
      }

    return axios(config);   
}