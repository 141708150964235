import React, { useState } from 'react';
import PrintIcon from "../../utils/icons/PrintIcon";
import OrderMenuList from "./OrderMenuList";
import PdfDocument from "./PdfDocument";
import {PDFDownloadLink} from "@react-pdf/renderer";
import {useClient} from "../../utils/context/ClientContext";

const OrderCard = ({ orderData }) => {
    const [detailsVisible, setDetailsVisible] = useState(false);
    const { tenantData } = useClient();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleDetails = () => {
        setDetailsVisible(!detailsVisible);
        setIsModalOpen(true); // Open the modal when "More Details" is clicked
    };

    const handleVisibility = (isVisible) => {
        setIsModalOpen(isVisible);
    };
    console.log(orderData.bill.billDetails.product)

    return (
        <div className="bg-white border border-l-green-500 border-l-4 rounded-lg shadow-lg p-2.5 w-full h-fit md:max-w-md mx-auto">
            {/* Order Header */}
            <div className="flex justify-between items-start gap-5">
                <div className="font-semibold text-primary items-center  border-b border-gray-300 pb-4 w-full">
                    <p>#{orderData?.orderId}</p>
                    <div className="flex justify-between items-center ">
                        <p className="text-gray-500 font-normal text-xs text-right flex gap-2">
                            <p className={"font-medium"}>Ordered at</p>{" "+orderData?.orderTime}
                        </p>
                    </div>
                </div>
                <div className={"text-right border rounded-lg p-3 border-gray-200"}>
                    <p className="font-normal text-sm">Table</p>
                    <p className="text-center font-bold">1</p>
                </div>
            </div>

            {/* Order Details */}
            <div className="flex justify-between items-center text-blue-gray-800">
                <div>
                    <p className="font-medium ">
                        Total Items: <span>{orderData?.bill?.billDetails?.length} </span>
                    </p>
                    <div>
                        <span
                            className="text-xs text-primary hover:text-primary-hover underline cursor-pointer"
                            onClick={toggleDetails}
                        >
                            More Details
                        </span>
                    </div>
                </div>
                <p className="text-xl font-bold text-right">₹{orderData.amount}</p>
            </div>

            <div className="flex justify-between items-center mt-4">
                <div className="flex items-center justify-between gap-3">
                    <button className={"bg-primary border text-white px-4 py-1 rounded-lg text-sm"}>
                        Delivered
                    </button>
                    <button className={"border-gray-700 text-gray-700 border px-4 py-1 rounded-lg text-sm"}>
                        Decline
                    </button>
                </div>
                {/*<PDFDownloadLink document={<PdfDocument orderData={orderData} clientData={tenantData}/>} fileName={orderData.orderId}>*/}
                {/*    <button >*/}
                {/*        <PrintIcon className="w-5 h-5 text-primary"/>*/}
                {/*    </button>*/}
                {/*</PDFDownloadLink>*/}
            </div>
            {/*<div className={"my-2"}>*/}
            {/*    <BorderLinearProgress variant="determinate" value={50} />*/}
            {/*</div>*/}

            {detailsVisible && (
                <OrderMenuList
                    orderData = {orderData}
                    isModalOpen={isModalOpen}
                    handleVisibility={handleVisibility}
                />
            )}
        </div>
    );
};

export default OrderCard;
