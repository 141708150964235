import * as React from 'react';
import Paper from '@mui/material/Paper';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import toast, { Toaster } from 'react-hot-toast';
import {PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import EditIcon from '../../utils/icons/Edit';
import { useClient } from '../../utils/context/ClientContext';
import CONFIG from '../../utils/Config';
import InfoIcon from '../../utils/icons/InfoIcon';
import Table from '../Table';


const handleEditClick = (id) => () => {
  alert(id);
};


const handleDeleteClick = (id) => () => {
  alert(id);
}
const dummyColumns = [
  { label: 'Name', content: 'Name' },
  { label: 'Age', content: 'Age' },
  { label: 'City', content: 'City' },
  { label: 'Address', content: 'Address' },
  { label: 'Number', content: 'Number' },
  { label: 'Email', content: 'Email' },
  { label: 'Address', content: 'Address' },
  { label: 'Number', content: 'Number' },
  { label: 'Email', content: 'Email' },
];

const dummyRows = [
  [
    { content: 'John Doe' },
    { content: <input type='checkbox'/> },
    { content: 'New York' },
    { content: 'Kurukku Sandhu' },
    { content: '978453210' },
    { content: 'test@email.com' },
  ],
  [
    { content: 'Jane Doe' },
    { content: '25' },
    { content: 'London' },
    { content: 'Kurukku Sandhu' },
    { content: '978453210' },
    { content: 'test@email.com' },
  ],
  [
    { content: 'Peter Pan' },
    { content: '12' },
    { content: 'Neverland' },
    { content: 'Kurukku Sandhu' },
    { content: '978453210' },
    { content: 'test@email.com' },
  ],
];

const paginationModel = { page: 0, pageSize: 5 };

export default function RoleList() {

  const clientContext = useClient();
  const [rows, setRows] = React.useState([{
    id: 1,
    name: "User"
  }]);
  const navigate = useNavigate();

  return (
    <div className="mt-5 md:mt-16 md:mb-56 p-5 md:p-10 w-9/12 max-w-7xl mx-auto shadow-md rounded-lg flex flex-col gap-10">
      <div className='flex justify-between items-center'>
        <div className='flex items-center justify-between gap-2'>
          <h1 className='font-primary-medium text-lg'>IAM Users & Details</h1>
          <Tooltip title='This list consist of all the administrator users who do have the access to view the dashboards and do other administrator operations.' arrow>
            <div className='cursor-pointer'>
              <InfoIcon width={16} height={16}/>
            </div>
          </Tooltip>
        </div>
        <div className='flex gap-3 justify-end'>
          <button className="flex gap-1 justify-center items-center h-8 w-36 font-primary-regular text-white bg-[#dc3545] hover:bg-primary-dark-red rounded-lg shadow-lg cursor-pointer">
            <TrashIcon width={'20px'} height={'20px'} /> <span className='text-sm'>Delete user</span>
          </button>
          <button className="flex gap-1 justify-center items-center h-8 w-36 font-primary-regular text-white bg-primary-blue hover:bg-primary-dark-blue rounded-lg shadow-lg cursor-pointer" onClick={() => {
            navigate('/user/add');
          }}>
            <PlusIcon width={'20px'} height={'20px'} /> <span className='text-sm'>Add new user</span>
          </button>
        </div>
      </div>
      <div className='w-6/12 mx-auto'>
        <Table columns={dummyColumns} rows={dummyRows}/>
      </div>
    </div>
  );
}
