import * as React from 'react';
import Paper from '@mui/material/Paper';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import toast, { Toaster } from 'react-hot-toast';
import {PlusIcon, TrashIcon } from '@heroicons/react/24/solid';
import EditIcon from '../utils/icons/Edit';
import axios from 'axios';
import CONFIG from '../utils/Config';
import { useClient } from '../utils/context/ClientContext';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '../utils/icons/InfoIcon';
import { Tooltip } from '@mui/material';
import Button from "./elements/inputs/Button";
import Tabs from "./elements/inputs/Tabs";


function renderRating(params) {
  return <EditIcon width={24} height={24} />;
}

const handleEditClick = (id) => () => {
  alert(id);
};


const handleDeleteClick = (id) => () => {
  alert(id);
}
const columns = [
  { field: 'id', headerName: 'ID' },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    width: 160
  },
  { field: 'emailId', headerName: 'Email', width: 300 },
  { field: 'role', headerName: 'Role', width: 160 },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    cellClassName: 'actions',
    getActions: ({ id }) => {
      return [
        <GridActionsCellItem
          icon={<EditIcon width={20} height={20} className={'text-gray-600'}/>}
          label="Edit"
          className="textPrimary"
          onClick={handleEditClick(id)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<TrashIcon width={20} height={20} className={'text-gray-600'}/>}
          label="Delete"
          onClick={handleDeleteClick(id)}
          color="inherit"
        />,
      ];
    }
  }
];

const paginationModel = { page: 0, pageSize: 5 };

export default function UserList() {

  const clientContext = useClient();
  const [rows, setRows] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    const rowArray = [];
    axios({
      url: CONFIG.URL + "user/getAdmins",
      method: "GET",
      withCredentials: true,
      headers: {
        'X-TenantID': clientContext.tenantID
      }
    }).then((res) => {
      res?.data?.map((data, count) => {
        const tempData = { id: count + 1, fullName: data.fullName, emailId: data.mailId, role: 'Admin' }
        rowArray.push(tempData);
      })
      setRows(rowArray);
    }).catch((err) => {
      if (err?.response?.status === 403) {
        toast.error('Token Expired please logout and login!');
      }
      toast.error("Something went wrong try again after sometime!");
      console.error(err.message);
    })
  }, [])

  const UserSection = () => {
    return (
        <div className={'flex flex-col gap-5'}>
          <div className='flex gap-3 justify-end'>
            <Button label={'Delete'} variant={'outline'} className={'w-24'}/>
            <Button label={'Add'} variant={'filled'} className={'w-24'} onClick={() => navigate('/user/add')}/>
          </div>
          <div>
            <Paper sx={{height: 400, width: '100%'}}>
              <DataGrid
                  rows={rows}
                  columns={columns}
                  initialState={{pagination: {paginationModel}}}
                  pageSizeOptions={[5, 10]}
                  checkboxSelection
                  sx={{border: 0.5, borderColor: '#e7e7e7'}}
              />
            </Paper>
          </div>
        </div>
    );
  }

  const tabContent = [
    {
      value: 1,
      header: 'Users',
      content: <UserSection/>
    },
    {
      value: 2,
      header: 'Roles',
      content: <UserSection/>
    },
    {
      value: 3,
      header: 'Permissions',
      content: <UserSection/>
    },
  ]
  return (
    <div className="mt-5 md:mt-16 md:mb-56 p-5 md:p-10 mx-auto shadow-md rounded-lg flex flex-col gap-10 w-full md:w-10/12">
      <div className='flex flex-col gap-5 w-full justify-start items-center'>
        <div className='flex items-center justify-between gap-2'>
          <h1 className='font-primary-medium text-lg'>IAM Users & Details</h1>
          <Tooltip
              title='This list consist of all the administrator users who do have the access to view the dashboards and do other administrator operations.'
              arrow>
            <div className='cursor-pointer'>
              <InfoIcon width={16} height={16}/>
            </div>
          </Tooltip>
        </div>
        <div className={'w-full'}>
          <Tabs content={tabContent} defaultSelectedTab={1}/>
        </div>
      </div>
    </div>
  );
}
