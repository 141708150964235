import DashboardIcon from "../../utils/icons/DashboardIcon";
import React from "react";


export const RecentOrderItem = () => {

    return (
        <>
            <div className={"flex justify-between items-center py-3 border-b border-gray-100"}>
                <div>
                    <p className={"text-sm font-medium text-primary"}>#A1254687489</p>
                    <div className="flex justify-between items-center ">
                        <p className="text-gray-500 font-normal text-xs text-right flex gap-2">
                            <p className={"font-medium"}>Delivered at</p>{" "}07:17:05 PM
                        </p>
                    </div>
                </div>
                <button
                    className={"text-xs py-1 px-2 bg-blue-light border border-primary rounded-lg hover:border-primary-hover"}>View
                    Order
                </button>
            </div>
        </>
    )
}
