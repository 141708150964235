import React from 'react'

function DashboardIcon({ width, height, className}) {
  return (
      <svg width={width} height={height} className={className} viewBox="0 0 24 24" fill="none"
           xmlns="http://www.w3.org/2000/svg">
          <g fill="none" stroke="currentColor" strokeWidth="2.5">
            <circle cx="19" cy="5" r="2.5"/>
            <path strokeLinecap="round" d="M21.25 10v5.25a6 6 0 0 1-6 6h-6.5a6 6 0 0 1-6-6v-6.5a6 6 0 0 1 6-6H14"/>
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="m7.27 15.045l2.205-2.934a.9.9 0 0 1 1.197-.225l2.151 1.359a.9.9 0 0 0 1.233-.261l2.214-3.34"/>
          </g>
      </svg>
  )
}

export default DashboardIcon