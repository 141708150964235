import React, { useEffect, useState } from 'react'
import OfferItem from './OfferItem';
import CallAxios from '../../utils/Axios';
import {useNavigate} from "react-router-dom";

export default function OfferList() {
   const navigate = useNavigate();

   const handleAddOffer = () => {
    navigate('/createOffer')
   }
  return (
      <div className=" md:px-10 mt-10 md:mt-20 w-11/12 xl:w-10/12 mx-auto">
          <div className={"w-full mx-auto flex justify-between items-center border-b border-gray-200 py-2 mt-10"}>
              <h3 className="text-xl font-primary-semibold">Offers</h3>
              <button
                  className={"text-sm border border-primary text-primary font-normal py-1 px-3 rounded-lg hover:bg-blue-100"}
                  onClick={handleAddOffer}>+
                  Create Offer
              </button>
          </div>

          <div
              className="w-full grid grid-auto-fit lg:grid-auto-fit-sm gap-3  mx-auto p-4 border rounded-2xl my-5"
          >
              <OfferItem/>
          </div>
      </div>
  )
}
